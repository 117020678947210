import React from 'react'
import Layout from '../../layout/Layout'
import Related from '../reusable/related';


class Epico extends React.Component{
    componentDidMount() {
        document.title = "Felixrt- Project: épico! "; 
     }
    
    render(){
        return (
            <React.Fragment>
                <Layout currentPage="Portfolio">
                    <div className="portfolio epico">
                        <section className="section">
                            <div className="container-fluid">
                                <div className="row mt-5 mb-0">
                                    <div className="col-md-10 col-11 mx-auto text-left">
                                        <h3 className="font-tertiary">Épico</h3>
                                    </div>
                                </div>
                                {/* Main Description */}
                                <div className="row main-description">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                        <h1 className="mb-3 px-3 px-md-0 font-tertiary">Entrepreneur responsive dashboard</h1>
                                        <p className="px-3 px-md-0">
                                            Épico is a dashboard app that allows entrepreneurs discover investors 
                                            and follow programs in order to learn about business and industries.
                                        </p>
                                    </div>
                                </div>
                                {/* Centred picture */}
                                <div className="row centred-image overview my-4 mb-5">
                                        <div className="col-11 col-md-12 mx-auto text-center">
                                            <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/epico/cover.png'} alt="Epico Cover" />
                                    </div>
                                </div>
                                 {/* Triple Text */}
                                 <div className="row triple-text mb-5 mt-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        
                                        <div className="row">
                                           
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Responsibilities</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>High fidelity components</li>
                                                    <li>Code components (HTML + SCSS/Bootstrap5)</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">My roles</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>UX/UI Designer</li>
                                                    <li>UX Engineer</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Date and (Duration)</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Jan 2021 (2 months)
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row centred-description-important pb-5">
                                    <div className="col-11 col-md-10 mx-auto text-left">
                                        <h3 className="mb-3 mt-5 pt-4 text-left">The full story</h3>
                                        <p className="mt-4">
                                            Please, contact me for the full case study.
                                       </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="mb-3">Related Projects</h4>
                                        <Related 
                                                
                                                title1="CYBERSECURITY MANAGED SERVICE"
                                                image1="/images/current/featured.png"
                                                type1="Current" 
                                                url1="Cyber"
                                                text1="ONGOING PROJECT"

                                                title2="SALARY COMPARISON TOOL"
                                                image2="/images/challenge/xcorp/feature.png"
                                                type2="Challenge" 
                                                text2="CHALLENGE"
                                                url2="Xcorp"
                                                 
                                                 />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Layout>
            </React.Fragment>
        )
    }
}
export default Epico;