import React, { useEffect } from 'react';
import Plotly from 'plotly.js-dist';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
const Coding = () => {
    useEffect(() => {
        const data = [
            {
              type: 'bar',
              x: [18, 22, 41, 45, 60, 100],
              y: ['Name', 'Phone number', 'Address', 'Website',  'Image', 'Password'],
              orientation: 'h',
              marker: {
                color: ['#71E9A8', '#71E3B0', '#71CDC6', '#71C5CB', '#69A1BF', '#69A0BD'],
              },
            },
          ];
          
  
      const layout = {
        title: '',
        font: {
          family: 'Arial, sans-serif',
        },
        xaxis: {
          title: '',
          showgrid: false,
          zeroline: false,
          showline: false,
          showticklabels: true,
          tickmode: 'array',
          tickvals: [0, 50, 100],
          ticktext: ['0%<br>No Users', '50%<br>Half Users', '100%<br>All Users'],
          range: [-5, 100], 
        },
        yaxis: {
          title: '',
          showgrid: false,
          zeroline: false,
          showline: false,
          showticklabels: true,
        },
        margin: {
          l: 150,
          r: 50,
          b: 50,
          t: 50,
        },
        shapes: [
            {
              type: 'line',
              x0: 0,
              y0: 0,
              x1: 0,
              y1: 1,
              xref: 'x',
              yref: 'paper',
              layer: 'below', 
              line: {
                color: '#eee',
                width: 1,
              },
            },
            {
              type: 'line',
              x0: 25,
              y0: 0,
              x1: 25,
              y1: 1,
              xref: 'x',
              yref: 'paper',
              layer: 'below', 
              line: {
                color: '#eee',
                width: 1,
              },
            },
            {
              type: 'line',
              x0: 50,
              y0: 0,
              x1: 50,
              y1: 1,
              xref: 'x',
              yref: 'paper',
              layer: 'below',
              line: {
                color: '#eee',
                width: 1,
              },
            },
            {
              type: 'line',
              x0: 75,
              y0: 0,
              x1: 75,
              y1: 1,
              xref: 'x',
              yref: 'paper',
              layer: 'below', 
              line: {
                color: '#eee',
                width: 1,
              },
            },
            {
              type: 'line',
              x0: 100,
              y0: 0,
              x1: 100,
              y1: 1,
              xref: 'x',
              yref: 'paper',
              layer: 'below', 
              line: {
                color: '#eee',
                width: 1,
              },
            },
          ],
        
      };
  
      Plotly.newPlot('barChart', data, layout);
    }, []);
  

  const dataCode = `
  const data = [
    {
      type: 'bar',
      x: [18, 22, 41, 45, 60, 100],
      y: ['Name', 'Phone number', 'Addresses', 'Website',  'Image', 'Password'],
      orientation: 'h',
      marker: {
        color: ['#71E9A8', '#71E3B0', '#71CDC6', '#71C5CB', '#69A1BF', '#69A0BD'],
      },
    },
  ];
  

const layout = {
title: '',
font: {
  family: 'Arial, sans-serif',
},
xaxis: {
  title: '',
  showgrid: false,
  zeroline: false,
  showline: false,
  showticklabels: true,
  tickmode: 'array',
  tickvals: [0, 50, 100],
  ticktext: ['0%<br>No Users', '50%<br>Half Users', '100%<br>All Users'],
  range: [-5, 100], 
},
yaxis: {
  title: '',
  showgrid: false,
  zeroline: false,
  showline: false,
  showticklabels: true,
},
margin: {
  l: 150,
  r: 50,
  b: 50,
  t: 50,
},
shapes: [
    {
      type: 'line',
      x0: 0,
      y0: 0,
      x1: 0,
      y1: 1,
      xref: 'x',
      yref: 'paper',
      layer: 'below', 
      line: {
        color: '#eee',
        width: 1,
      },
    },
    {
      type: 'line',
      x0: 25,
      y0: 0,
      x1: 25,
      y1: 1,
      xref: 'x',
      yref: 'paper',
      layer: 'below', 
      line: {
        color: '#eee',
        width: 1,
      },
    },
    {
      type: 'line',
      x0: 50,
      y0: 0,
      x1: 50,
      y1: 1,
      xref: 'x',
      yref: 'paper',
      layer: 'below',
      line: {
        color: '#eee',
        width: 1,
      },
    },
    {
      type: 'line',
      x0: 75,
      y0: 0,
      x1: 75,
      y1: 1,
      xref: 'x',
      yref: 'paper',
      layer: 'below', 
      line: {
        color: '#eee',
        width: 1,
      },
    },
    {
      type: 'line',
      x0: 100,
      y0: 0,
      x1: 100,
      y1: 1,
      xref: 'x',
      yref: 'paper',
      layer: 'below', 
      line: {
        color: '#eee',
        width: 1,
      },
    },
  ],

};
  `;

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '500px' }}>
      <div id="barChart" style={{ width: '50%', height: '100%' }}></div>
      <div style={{ width: '50%', height: '100%', overflowY: 'auto', backgroundColor: '#292C33', color: '#F8F8F2' }}>
        <SyntaxHighlighter language="javascript" style={atomOneDark} showLineNumbers customStyle={{color: '#01F798'}}>
          {dataCode}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default Coding;
