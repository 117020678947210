import React from 'react'
import $ from 'jquery'
import { Link } from "react-router-dom";

const SingleRelated = ({ title, image, url, type, text }) => {
    return (
        
        <Link
            to={`/${type}/${url}`}            
        >   
            <div className='hoverable'>
              <div className="related-cover">
                <div className="title-mask align-items-center">
                    <h3 className="text-center">{text}</h3>
                </div>
                <img className="about-img-crop" src={ process.env.PUBLIC_URL + image} 
                                                    alt={title}/>
              </div>
            </div>
           
        </Link>
    );
};

class Hoverable extends React.Component {
    
    render(){
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <SingleRelated image={this.props.image} type={this.props.type} text={this.props.text} url={this.props.url} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Hoverable;