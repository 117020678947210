import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands, regular,solid } from '@fortawesome/fontawesome-svg-core/import.macro'

var Scroll   = require('react-scroll');
var scroller = Scroll.scroller;

class Header extends React.Component {
    scrollTo() {
        scroller.scrollTo('HomePortfolio', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -50
        })
      }
    render(){
        return (
            <React.Fragment>
                <section className="hero-area bg-primary position-relative">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-11 col-md-10 mx-auto text-center px-0">
                            <h2 className="cover-text font-secondary mb-0">
                                    Felix<span className='special'>rt</span>
                                </h2>
                                <h2 className="cover-text font-secondary pb-2">
                                    Product Designer
                                </h2>
                                <div className="row mt-3">
                                    <div className="col-8 mx-auto">
                                        <p className="cover-paragraph font-primary pl-1 pr-3">
                                        With expertise in cybersecurity and a strong background in computer science, I'm dedicated to building a secure and sustainable future for all
                                        </p>
                                     
                                    
                                    </div>
                                </div>                                
                                
                            </div>
                        </div>
                        
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default Header;