import React from 'react'
import Layout from '../../layout/Layout'
import Related from '../reusable/related';


class Cyber extends React.Component{
    componentDidMount() {
        document.title = "Felixrt - Current: Cybersecurity Managed Services Omni-Channel Design"; 
     }
    
    
    render(){
        return (
            <React.Fragment>
                <Layout currentPage="Portfolio">
                    <div className="portfolio viz">
                        <section className="section">
                            <div className="container-fluid">

                                {/* Centered Image */}
                                <div className="row centred-image overview mb-5 cover">
                                    <div className="col-11 col-md-12 mx-auto text-center p-2 pt-4 py-2 p-md-4">
                                        <img className="portfolio-img p-md-5 mx-md-4 pt-4 mt-3" src={ process.env.PUBLIC_URL + '/images/portfolio/cyber/cover.jpg'} alt="Cyber cover" />
                                    </div>
                                </div>
                                
                            <div className="row mt-5 pt-5 mb-0">
                                <div className="col-md-10 col-11 mx-auto text-left">
                                    <h1 className="mb-1 px-3 px-md-0 font-tertiary">Cybersecurity Managed Services Omni-Channel Design</h1>
                                    </div>
                                </div>
                                {/* Main Description */}
                                <div className="row main-description">
                                    <div className="col-md-10 offset-md-1 col-sm-12 text-left">
                                    <span className="tagline current mb-4">ONGOING</span>
                                        
                                       
                                        <p className="px-3 px-md-0">
                                        I've designed cybersecurity tools across multiple platforms: iOS, Jinja, Microsoft Teams, Plotly, Retool and Splunk to name a few, enabling security teams and business leaders to act swiftly on critical insights. My role includes researching and prototyping visualizations that provide clear perspectives on risks from an privacy perspective, helping my client make fast, informed decisions and allowing teams to efficiently safeguard VIPs and key assets. 

                                        </p>
                                    </div>
                                </div>

                                 {/* Triple Text */}
                                 <div className="row triple-text mb-5 pb-0 mt-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        
                                        <div className="row">
                                           
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">My Role</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>Service (Omni Channel) Designer</li>
                                                    <li>Product Designer</li>
                                                    <li>UI Designer</li>
                                                    <li>UX Engineer</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Date and (Duration)</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Jan 2023 (ongoing)
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row centred-image overview mb-4 py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12 col-md-6 mx-auto text-center p-3">
                                                <img className="about-img" src={ process.env.PUBLIC_URL + '/images/portfolio/cyber/cyberb.png'} 
                                                    alt="cyber 1"/>
                                            </div>
                                            <div className="col-12 col-md-6 mx-auto text-center p-3">
                                                <img className="about-img" src={ process.env.PUBLIC_URL + '/images/portfolio/cyber/cybera.png'} 
                                                    alt="cyber 2"/>
                                            </div>
                                        </div>
                                        <div className="row mx-auto text-center">
                                            <div className="col-12"><p>Main dashboard peek</p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row triple-text mb-4 pb-0 mt-2">
                                    <div className="col-11 col-md-10 mx-auto">
                                        
                                        <div className="row">
                                            
                                            <div className="col-12 col-md-6 my-2">
                                                <h4 className="mb-2">Impact</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>I helped corp successfully change from being a SaaS oriented to a managed services oriented business, mutating every front-stage channel and internal tools.</li>
                                                    <li>Corp found product market fit and accelerated the process of demos, POVs and prospect conversions after the rapid iteration processes lead by the product team and in collaboration with the entire organization.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                        
                                            <div className="col-12 col-md-6 my-4">
                                                <h4 className="mb-2">Challenges</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>Designing for a technology primarily made for administrative tasks, I successfully delivered aesthetically pleasing and user-friendly dark mode interfaces for our High-Value Target (VIP) users</li>
                                                    <li>Demonstrated the technical feasibility and value of multiple interfaces, utilizing a range of highly technical skills such as data science, computer science, and programming</li>
                                                    <li>Use an agile hybrid design process to stay ahead of a velocity first startup mindset collaborators</li>
                                                    
                                                </ul>
                                            </div>
                                        </div>
                             
                                       
                                    </div>
                                </div>
                                <div className="row centred-image overview mb-4 py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                          
                                            <div className="col-12 col-md-6 mx-auto text-center p-4">
                                                <img className="about-img" src={ process.env.PUBLIC_URL + '/images/portfolio/cyber/cyberd.png'} 
                                                    alt="cyber 4"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row triple-text mb-4 pb-0 mt-2">
                                    <div className="col-11 col-md-10 mx-auto">
                                        
                    
                                        <div className="row">
                                            <div className="col-12 col-md-6 my-4">
                                                <h4 className="mb-2">Deliverables</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>High fidelity mockups and Interactions of Dashboards: Customer faced summary, Administration, Analysis</li>
                                                    <li>High fidelity mockups and Interactions of Data Visualizations: Peer benchmark comparison charts, stacked charts for remediation metrics, timeline metrics, geo location data, domains displays, radar chart for before/after comparison, route traffic graphs</li>
                                                    <li>Made sense of already used components to create  a design system that is being used today to generate content by product, marketing, sales, engineering and design</li>
                                                    <li>Prototypes</li>
                                                    <li>Customer insights</li>
                                                    <li>Team insights</li>
                                                    <li>Dashboard Demo storytelling</li>
                                                    <li>Dashboard Proof of value storytelling</li>
                                                    <li>Video demo storytelling</li> 
                                                    <li>Managed services team tools enhancements</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 my-4">
                                                <h4 className="mb-2">Technologies</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>Figma</li>
                                                    <li>Adobe creative suite</li>
                                                    <li>Retool</li>
                                                    <li>Plotly</li>
                                                    <li>Test and style of components using: plotly javascript version for visualizations.</li>
                                                    <li>Styling with css, scss, html, and/or within retool components.</li>
                                                    <li>Test and style of email components using email responsive standards (layout using tables)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    
                                <div className="row">
                                    <div className="col-11 col-md-10 my-4 mx-auto">
                                        <h4 className="mb-2">Want to learn more?</h4>
                                        <p className="pr-md-2 pr-sm-0">
                                            Scroll for my contact!
                                        </p>
                                    </div>
                                </div>
                                 
                      
                                <div className="row">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="mb-3">Related Projects</h4>
                                        <Related 
                                                
                                                title1="THIRD PARTY NETWORK"
                                                image1="/images/portfolio/viz/home.png"
                                                type1="Portfolio" 
                                                url1="NetworkProviderDashboard"
                                                text1="DATA VISUALIZATION"

                                                title2="CYBERSECURITY DASHBOARD REDESIGN"
                                                image2="/images/challenge/proserpexa/ds1.png"
                                                type2="Portfolio"
                                                url2="Dashboard"
                                                text2="CYBERSECURITY DASHBOARD"
                                                 
                                                 />
                                    </div>
                                </div>
                              
                                
                            </div>
                        </section>
                    </div>
                </Layout>
            </React.Fragment>
        )
    }
}
export default Cyber;