import React from 'react'
import Layout from '../../layout/Layout'
import Related from '../reusable/related';


class PentaCad extends React.Component{
    componentDidMount() {
        document.title = "Felixrt- Project: PentaCad "; 
     }
    
    
    render(){
        return (
            <React.Fragment>
                <Layout currentPage="Portfolio">
                    <div className="portfolio pentacad">
                        <section className="section">
                            <div className="container-fluid">
                                <div className="row mt-5 mb-2">
                                    <div className="col-md-10 col-11 mx-auto text-left">
                                        <h1 className="font-tertiary">PentaCad</h1>
                                    </div>
                                </div>
                                {/* Main Description */}
                                <div className="row main-description">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                        <h3 className="mb-3 px-3 px-md-0">Case study</h3>
                                        <p className="px-3 px-md-0">
                                            PentaCad is a CAD Software Product that helps electrical engineers design complex Printed Circuit Boards(PCBs), 
                                            by connecting wires and symbols in the Schematic view, and Footprints in the Layout view.
                                        </p>
                                    </div>
                                </div>
                                 {/* Triple Text */}
                                 <div className="row triple-text mt-3 mb-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Project Duration</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    May 2018 (2+ years)
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">My role</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>UX Designer</li>
                                                    <li>Interaction Designer</li>
                                                    <li>UX Researcher</li>
                                                    <li>UI Designer</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Responsibilities</h4>
                                                <ul className="">
                                                    <li>User research</li>
                                                    <li>Competitive audit</li>
                                                    <li>Sketching</li>
                                                    <li>Wireframing</li>
                                                    <li>Usability study</li>
                                                    <li>High fidelity mockups</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Centred picture */}
                                <div className="row py-5 centred-image overview cover">
                                    <div className="col-md-8 col-sm-12 mx-auto text-center">
                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/main.jpg'} alt="PentaCad cover" />
                                    </div>
                                </div>

                                 {/* Understanding */}
                                 {/* main ping pong */}
                                 <div className="row fourthe-text-important py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 px-3 px-md-0">Initial conversations</h3>
                                                <p className="px-3 px-md-0 mb-0">
                                                    The owner of PentaLogix S.A approached me with the idea of bringing his current licensing 
                                                    Software Products to the SaaS era. They already had 3 different Products with about 
                                                    2K users combined. A free Gerber PCB Viewer, a Layout PCB Designer and a 
                                                    Footprint PCB Designer. In order to keep their current business model, 
                                                    and in order to reduce costs, he wanted to use a single base design and code, 
                                                    and to build a free online viewer and two desktop SaaS CAD Designers using that base.
                                                </p>
                                            </div>
                                        </div>
                                        {/* ping pong photo descriptions */}
                                        <div className="row ping-pong-photo pt-0 mb-5">
                                            <div className="col-12 mx-auto">
                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                        <h5 className="mb-2 text-left">Early sessions</h5>
                                                        <ul className="mb-5 mb-md-0">
                                                            <li>We did a couple of sessions of brainstorming functionalities, where the stakeholders already had a vision of the new products.</li>
                                                            <li>They would keep bringing up several brands that were already in the market.</li>
                                                            <li>We discussed the translation from the current pool of software to the new ones in terms of: 
                                                                modules and functionalities, technology to use, marketing and business models, scope and deadlines, methodology.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/Pentacad/brainstorm.png'} 
                                                                    alt="Paper Wireframes"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                
                                
                                  
                                 {/* small photo descriptions */}
                                 <div className="row small-photo-description pt-4 odd">
                                    <div className="col-10 mx-auto py-5 mt-3">
                                        <div className="row text-left">
                                            <div className="col-12 col-md-12">
                                                <h4 className="mb-4">User personas</h4>
                                                <p className="px-3 px-md-0">
                                                    We already had a big database with a lot of information about the users, 
                                                    must of them Electrical Engineers,
                                                    such as demographics, industry related, and surveys collected along the years;
                                                    with that as a base, I also performed remote interviews to collect qualitative data. 
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row text-left">
                                            <div className="col-12 col-md-4">
                                                <h5 className="mb-3">John (He/Him)</h5>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-7">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 mb-3">
                                                        
                                                        <img className="portfolio-img" 
                                                            src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/moises.png'} 
                                                            alt="John"  />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <h5 className="mb-3">Goals</h5>
                                                        <ul className="display body-2">
                                                            <li>To test run electrical tests efficiently.</li>
                                                            <li>To have time to go out with his dog.</li>
                                                        </ul>
                                                        <h5 className="mt-3">Frustrations</h5>
                                                        <ul className="display body-2">
                                                            <li>Testing tools are tedious and error prone.</li>
                                                            <li>Not being able to incorporate his past designs easily.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-12">
                                                        <p className="display body-2">
                                                            John likes his life on the Mountains of Pennsylvania. He's worked most of his life 
                                                            in the Electrical 
                                                            industry and it is about to retire. He wishes to continue doing his job, 
                                                            but the Software he uses to design demands a lot of time and concentration.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-5 pl-0 pl-md-5">
                                                <h5 className="mb-3">Problem statement</h5>
                                                <p className="display body-2">
                                                    John is an Electrical Engineer who needs a better way to design and test his PCBs, 
                                                    because he wants to continue doing being competitive in the industry while having extra 
                                                    time to spend with his dog and family. 
                                                </p>
                                                <h5 className="mt-3">Demographics</h5>
                                                <ul className="display body-2">
                                                    <li><strong>Age:</strong> 55</li>
                                                    <li><strong>Education:</strong> BEng.</li>
                                                    <li><strong>Location:</strong> Pennsylvania, USA</li>
                                                    <li><strong>Family:</strong> Married, 2 children</li>
                                                    <li><strong>Occupation:</strong> Electr. Engineer</li>    
                                                </ul>
                                            </div>
                                               
                                        </div>
                                    </div>  
                                </div>
                                {/* Triple Text */}
                                <div className="row triple-text pb-5 mb-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Pain Points</h4>
                                        <div className="row text-center">
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Journey time</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                 They would lose time uploading the same or similar information to various applications.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Information loss</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    They would lose information when passing large chunks of data.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Reuse</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Reusing past designs for new projects are tedious and error prone.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                4
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Technology</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Feeling that Softwares don't keep up with current technology standards like continuity and cloud storage.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row fourthe-text-important py-5 mb-5">
                                    <div className="col-11 col-md-10 mx-auto">

                                        <div className="row align-items-top py-5">
                                            <div className="col-12 col-md-6">
                                                <h3 className="mb-3">Problem Statement</h3>
                                                <p>
                                                    Users lose focus and information when switching from one 
                                                    application to another in order to complete a single PCB Design.
                                                    The PCB Services Company had several usable applications to design PCB Footprints, 
                                                    Layouts, Symbols and more, including a free viewer that needed to be updated in order 
                                                    to remain competitive. 
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <h3 className="mb-3">Goal Statement</h3>
                                                <p>
                                                    The new CAD Software will helps users create a PCB Design with all the physical 
                                                    and logical components connected within a single application. It will be a SaaS in otder 
                                                    to be competitive with existing solutions and it will use Clound interactions to 
                                                    offer continuity and availability; 
                                                    which will result in an easier share, comparison and testing 
                                                    of all the parts.
                                                    The measurables will be: at least 20% of new users after a year of launch, a 40% increase in time on task, 
                                                    and at least 80% of users finding the software “easy to use”.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 px-3 px-md-0">Action Plan</h3>
                                                <p className="px-3 px-md-0 mb-2">
                                                    After an analysis of all of the information above, we agreed to prioritize the following:
                                                </p>
                                                <ul className="mb-5">
                                                    <li>Create a single product with all the functions of the old products, but with constraints depending on the pricing plan.</li>
                                                    <li>Reimagine the User-Computer Interactions for them to be competitive with the state-of-the-art.</li>
                                                    <li>Design a Wokspace that guarantees different layout views depending on the stage of the PCB Design process and designer preference.</li>
                                                    <li>Redesign pin management interactions, so Designers can control large amounts of Pins.</li>
                                                    <li>Incorporate datasheet tools, for Designers to access semantically and handle it automatically.</li>
                                                    <li>Connect the Software to the cloud.</li>
                                                    <li>Use an Agile methodology in order to always have an MVP for testing.</li>
                                                    <li>Start working with low fidelity prototypes and iterating towards high fidelity from the 4-5 month.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                         {/* Centred Important Description */}
                                <div className="row centred-description-important odd">
                                    <div className="col-md-10 col-10 mx-auto text-left my-5 py-5">
                                        <h3 className="mb-4 text-left">Sprinting ahead of Engineering's scrum</h3>
                                        <img className="portfolio-img mt-2" src={ process.env.PUBLIC_URL + '/images/portfolio/Pentacad/plan2.png'} 
                                                                    alt="Design plan"/>

                                       
                                    </div>
                                </div>

                            
                                
                                {/* main ping pong */}
                                 <div className="row fourthe-text-important py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 px-3 px-md-0">Understanding the concepts</h3>
                                                <p className="px-3 px-md-0 mb-0">
                                                    From studying the subject and meetings with experts we understood the following:

                                                </p>
                                            </div>
                                        </div>
                                        {/* ping pong photo descriptions */}
                                        <div className="row ping-pong-photo pt-0 mb-5">
                                            <div className="col-12 mx-auto">
                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                        <h5 className="mb-2 text-left">How are PCBs designed?</h5>
                                                        <ul className="mb-5 mb-md-0">
                                                            <li>The simplest PCB would be called a PCB Part.</li>
                                                            <li>A PCB Part has a logical representation that is called 'Symbol'. </li>
                                                            <li>It also has a physical representation of its placement called a 'Footprint'.</li>
                                                            <li>A PCB Part could also have a 3D view in the real world.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/Pentacad/pcb-concepts.png'} 
                                                                    alt="PCB Concepts"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ping-pong-photo pt-0 mb-2">
                                            <div className="col-12 mx-auto">
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 pr-md-5 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/Pentacad/competitive-audit.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                        <h5 className="mb-2 text-left">Competitive Audit</h5>
                                                        <ul className="mb-2 mb-md-0">
                                                            <li>There were a couple of solutions out there, including Software products like: Altium, Kicad and Eagle.</li>
                                                            <li>Most of them targeted worldwide Engineers.</li>
                                                            <li>Some key opportunities I found were: to get a better match between system and real world, better use of contrast and heuristics.</li>
                                                            <li>Another improvement could be to give better visual accessibility, by offering different reading options like: themes with larger font sizes, and text to speech compatibility.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          
                                 {/* Triple Text */}
                                 <div className="row triple-text py-5 my-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-4 mt-5">Ready... Set... Go!</h3>
                                        <h4 className="text-left my-2">Sprinting the solution</h4>
                                        <p className="px-3 px-md-0 mb-0">
                                        I was responsible for sprinting the following modules:
                                        </p>
                                        <div className="row text-center mb-5 mt-4">
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Importing a part (From BomA.)</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Importing parts from a large repository of Parts stored on the cloud.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Pin Composer</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    A multifuntional table that works as a datasheet where users can read, create, edit or delete pins.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Placing Interactions</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Creating the Human-Computer interaction to place and validate objects to the workspace.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row triple-text py-5 mb-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3">User flow</h3>
                                        <p>
                                            A typical use of all the modules would be when importing an existing part and connecting it to an existing project, 
                                            in that case, this would be the flow:
                                        </p>
                                        <img className="portfolio-img my-2" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/user-flow.png'} alt="Planning and methodolody" />
                                        
                                        
                                    </div>
                                </div>

                                <div className="row triple-text py-4 pb-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3 mt-5 pt-4">1. Bom Admin Import</h3>
                                        <h4>Architecture</h4>
                                        <p>
                                            Bom Admin is a cloud service that can be consumed through an API. This API will return a Part from different manufacturers.
                                            A Pentacad project can receive this Part and store it in memory or in the Library.
                                        </p>
                                        <img className="portfolio-img mb-3" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/bomadmin-ia.png'} alt="BOM Admin IA" />
                                        <h3 className='mt-4 mb-2'>Steps to import a part</h3>
                                        <h4 className='mb-3'>Wireframes</h4>
                                        <img className="portfolio-img mb-2" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/bomadmin-wireframes.png'} alt="BOM Admin Wireframes" />
                                        <p className="mb-5 mt-3">
                                            In Step 1, to an already existing project with parts connected to each other, 
                                            user will be able to select import from Bom Admin, then in Step 2, a list of 
                                            Parts metadata will be downloaded from the cloud to present available parts 
                                            from different manufacturers and their respective datasheet, for users to do 
                                            and informed selection, finally in Step 3, users will get an Imported Part, 
                                            but before searching for connection placement, it can be reviewed and edited 
                                            in the <strong>Pin Composer</strong>.
                                        </p>
                                    </div>
                                </div>

                            {/* main ping pong */}
                               <div className="row fourthe-text-important py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 px-3 px-md-0">2. The Pin Composer</h3>
                                            </div>
                                        </div>
                                        {/* ping pong photo descriptions */}
                                        <div className="row ping-pong-photo pt-0 mb-5">
                                            <div className="col-12 mx-auto">

                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">Wireframing the composer</h5>
                                                        <ul className="mb-5 mb-md-0">
                                                            <li>I wanted to do a simile of Ms. Excel and other datasheet editors.</li>
                                                            <li>Every row would represent a pin and its meta information</li>
                                                            <li>The header would contain the Pin editor actions.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/PentaCad/pin-composer-rapid-prototype.png'} 
                                                                    alt="Paper Wireframes"/>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 pr-md-5 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/PentaCad/pin-composer-rapid-wireframe-edit.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                        
                                                        <h5 className="mb-2 text-left">Composer handling and editing </h5>
                                                        <p className="mb-5 mb-md-0">
                                                            I did several versions of the pin editing, including a mass editing at the top, 
                                                            a cell by cell editing, and a row single pin editing.
                                                        </p>

                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">Situational Accessibility </h5>
                                                        <p className="mb-5 mb-md-0">
                                                            I noticed that there were a significant number of users that handled this data without using a mouse or a touchpad, 
                                                            so I also provided keyboard interaction rules, that function similar to a spreadsheet software app.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/situational-accessibility.png'} 
                                                                    alt="Paper Wireframes"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                               {/* main ping pong */}
                               <div className="row fourthe-text-important py-5 my-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 px-3 px-md-0">3. Placing Interactions</h3>
                                            </div>
                                        </div>
                                        {/* ping pong photo descriptions */}
                                        <div className="row ping-pong-photo pt-0 mb-5">
                                            <div className="col-12 mx-auto">

                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">Schematic Pin Placement</h5>
                                                        <ul className="mb-5 mb-md-0">
                                                            <li>Pins, on a circuit, are used to represent input/output signals.</li>
                                                            <li>In order for a Pin to be inserted, current Software would ask for coordinates and relative origin.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/Pentacad/pin-placement-current.png'} 
                                                                    alt="Post it session"/>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 pr-md-5 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/Pentacad/real-world-pcb-manufacturing.png'} 
                                                            alt="Real world PCB Manufacturing"/>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                        
                                                        <h5 className="mb-2 text-left">The real-world PCB Manufacturing</h5>
                                                        <p className="mb-5 mb-md-0">
                                                            We did a course on Board manufacturing, as well as a Videoconference session with the manufacturing HQ,
                                                             In order to understand how they connected Pins in the real world and how it relates to the 
                                                             Layout physical components.

                                                        </p>

                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">Revolutionizing the Pin Placement: On paper</h5>
                                                        <p className="mb-5 mb-md-0">
                                                            To ideate the interaction of inserting several pins at once to the board grid. 
                                                            I started picturing a simile to a classic drag and drop. 
                                                            I draw several interactions with different types of feedback, 
                                                            including smooth proximity highlight ones and strict discrete snap to grid ones.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/Pentacad/paper-ideation.png'} 
                                                                    alt="Paper Wireframes"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row triple-text pt-3">
                                    <div className="col-12 col-md-10 mx-auto">
                                        <h3 className="text-left mb-2 mt-5">Design System</h3>
                                        <h4>Created in collaboration</h4>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/design-system.png'} alt="Design System" />
                                    </div>
                                </div>
                                

                                 {/* ping pong photo descriptions */}
                                 <div className="row ping-pong-photo py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row text-center">
                                            <div className="col-12">
                                            <h4 className="mb-3">HiFi Prototype</h4>
                                            </div>
                                            
                                        </div>    
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-4">
                                                <h5 className="mb-2 text-left">Pin Placement</h5>
                                                <h6 className="mb-2 text-left">Iteration: smooth</h6>
                                                <p className="mb-5 mb-md-0">
                                                    Two ways of interacting with the grid were A/B tested. The Smooth one, showing here, would highlight the placement 
                                                    position as the user passes over the available vertice.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/PinsAnimation1.gif'} 
                                                            alt="Interaction 1"/>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/PinsAnimation2.gif'} 
                                                    alt="Digital Wireframe 2"/>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                
                                                <h5 className="mb-2 text-left">Pin Placement</h5>
                                                <h6 className="mb-2 text-left">Iteration: discrete</h6>
                                                
                                                <p className="mb-5 mb-md-0">
                                                    The Discrete one, would limit the cursor position to snap into the available vertice.
                                                </p>
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>

                                
                               {/* Triple Text */}
                               <div className="row triple-text py-5 mb-3 odd">
                                        <div className="col-11 col-md-10 mx-auto mb-4">
                                            <h3 className="text-center my-3 mt-5">Usability Iterations</h3>
                                            <h4 className="my-2">Study details</h4>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <h4>Research Questions</h4>
                                                    <ul className="ml-0">
                                                        <li>Is pin placement usable?</li>
                                                        <li>What interactive way of inserting a pin is faster for the user (discrete or smooth)?</li>
                                                        <li>Can user perform all the operations within the Pin Composer</li>
                                                        <li>How fast can they edit pins on the composer?</li>
                                                        <li>How easy is it to control the pin composer with the keyboard?</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <h4>Participants</h4>
                                                    <ul>
                                                        <li>3 recurrent male in several usability tests.</li>
                                                        <li>40 - 50 years old.</li>
                                                        <li>Living in the USA.</li>
                                                        <li>3 used glasses.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <h4>Methodology</h4>
                                                    <ul>
                                                        <li>Moderated usability study</li>
                                                        <li>Remote</li>
                                                        <li>Users were asked to use a static software prototype.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        

                                        </div>
                                   
                                    </div>
                                    <div className="row triple-text pb-5 mb-5 mt-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Insights</h4>
                                        <div className="row text-center">
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Discrete</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    A discrete way of inserting the pins makes users more comfortable than a smooth one.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Keyboard velocity</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    Tasks are finished 10 times faster, with the 3rd-4th time users use the keyboard shortcuts.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Massive first</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    Users prefer to perform operations, first on several groups of pins (Massive editor),
                                                     and then on specific pins.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                4
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">CTRL+A</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    Users wanted to use CTRL+A for selecting all the rows.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <h3>High Fidelity Screens</h3>
                                        <h4 className="text-left my-3 mt-5">Pin Composer</h4>
                                        <p>
                                        An editable glossary of all the Pins in the project.
                                                    It has a Spreadsheet look, feel and use, because most users organized them on used spreadsheet editors.
                                        </p>
                                        <img className="portfolio-img my-2" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/pin-creator5.jpg'} 
                                                            alt="Pin Composer"/>
                                        <h4 className="text-left my-3 mt-5">Import from Bom Admin</h4>
                                        <p>
                                            Bom admin parts and their meta data.
                                        </p>
                                        <img className="portfolio-img my-2" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/bomadmin.jpg'} 
                                                            alt="Bom admin"/>
                                        <h4 className="text-left my-3 mt-5">Preference Templates</h4>
                                        <p>
                                            To remember preferred sizes, layout positions, visible menus and others.
                                        </p>
                                        <img className="portfolio-img my-2" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/templates.jpg'} 
                                                            alt="Templates"/>
                                        <h4 className="text-left my-3 mt-5">Workspace tabs</h4>
                                        <p>
                                            For users to be able to jump between views, as if they're switching between browser's tabs.
                                        </p>
                                        <img className="portfolio-img my-2" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/main.jpg'} 
                                                            alt="Main"/>
                                        <h4 className="text-left my-3 mt-5">Work area contrast</h4>
                                        <p>
                                        To spot all the components.
                                        </p>
                                        <img className="portfolio-img my-2" src={ process.env.PUBLIC_URL + '/images/portfolio/pentacad/contrast.jpg'} 
                                                            alt="Contrast"/>
                                    </div>
                                </div>
                                

                                 {/* Centred Important Description */}
                                 <div className="row centred-description-important py-5 mb-0 mt-5">
                                    <div className="col-11 col-md-10 mx-auto text-left mb-5">
                                        <h3 className="mb-3 text-center">Conclusions and Follow up</h3>
                                        <p className="lead-paragraph">
                                            PentaCad made me put in practice my engineering background, 
                                            my design skills and processes, where I understood the fastest way we could go 
                                            was being faithful to the concepts;
                                            and my soft skills to keep the ship going for the longest project I've worked on so far. 
                                            PentaCad is not yet in the market, but it was a joy to work on;
                                            I know part of my team is still testing and only hope for the best when it comes out in 2023.
                                            </p>
                                            <p className='mt-3'>+ Wouldn't it be nice to have an integration with Cortana, for main statuses and actions? ;)
                                            </p>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="mb-3">Related Projects</h4>
                                        <Related 
                                                
                                                title1="CYBERSECURITY MANAGED SERVICE"
                                                image1="/images/current/featured.png"
                                                type1="Current" 
                                                url1="Cyber"
                                                text1="ONGOING PROJECT"

                                                title2="SALARY COMPARISON TOOL"
                                                image2="/images/challenge/xcorp/feature.png"
                                                type2="Challenge" 
                                                text2="CHALLENGE"
                                                url2="Xcorp"
                                                 
                                                 />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Layout>
            </React.Fragment>
        )
    }
}
export default PentaCad;