import React from 'react'
import Layout from '../../layout/Layout';
import Skills from '../misc/Skills'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  brands, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

class About extends React.Component{
    componentDidMount() {
        document.title = "Felixrt- About "; 
     }
    
    render(){
        return (
            <React.Fragment>
                <Layout currentPage="about">
                    <section className="pt-5">
                        
                            <div className="row mt-5 pb-2">
                                <div className="col-md-10 col-11 mx-auto ">   
                                    <div className="row align-items-center">
                                        <div className="col-md-7 col-12">
                                            <div className="row centred-description-important">
                                                <div className="col-12 mx-auto col-xl-12 mx-xl-0 text-left mt-4 ">
                                                    <h2 className="font-tertiary mb-4">About me</h2>
                                                    <h5 className="mb-4">FÉLIX RIVAS TOMALÁ (HE/HIM)</h5>
                                                    <p className="lead-paragraph px-xl-0 py-0 mb-5">
                                                        I'm known for successfully driving the creation of digital products from ideation and technical feasibility through to understanding the end-user, 
                                                        completing the cycle. My work, a result of collaborative efforts, has produced beautifully functional interfaces, 
                                                        achieved product-market fit, and fostered key partnerships.
                                                    </p>
                                                    <div className="row lead-paragraph mt-3">
                                                        <div className="col-12 col-md-6 text-left mb-2">
                                                            <a href="https://docs.google.com/document/d/1PKfvPrzfo1oTHzFItXzfIiI9Q7Cln1Zh58TgAktwR_Y/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon icon={regular('file')} /><h6 className='d-inline'> Resumé</h6>
                                                                
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="row lead-paragraph">
                                                        <div className="col-12 col-md-6 text-left mb-3">
                                                                <a href="mailto:frivasto@gmail.com" rel="noreferrer"> 
                                                                    <FontAwesomeIcon icon={regular('envelope')} /><h6 className='d-inline'> frivasto@gmail.com </h6>
                                                                </a>
                                                            </div>
                                                        </div>
                  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 pt-md-2 col-12 mt-4 mt-md-0">
                                            <img className="about-img" src={ process.env.PUBLIC_URL + '/images/home/me.jpeg'} 
                                                    alt="About Felix"/>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                            

                            </section>
                            <Skills />
                            
                
                
            </Layout>
        </React.Fragment>
        )
    }
}

export default About;