import React from 'react';
import Layout from '../../layout/Layout';
import Related from '../reusable/related';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

class NetworkProviderDashboard extends React.Component {
    componentDidMount() {
        document.title = "Felixrt - Network Provider Dashboard";
    }

    render() {
        return (
            <React.Fragment>
                <Layout currentPage="Portfolio">
                    <div className="portfolio viz">
                        <section className="section">
                            <div className="container-fluid">

                                {/* Centered Image */}
                                <div className="row centred-image overview mb-5 cover">
                                    <div className="col-11 col-md-12 mx-auto text-center p-2 pt-4 py-2 p-md-4">
                                    <video
                                            className="portfolio-video mt-5"
                                            src={process.env.PUBLIC_URL + '/videos/viz.mov'}
                                            autoPlay
                                            loop
                                            muted
                                            playsInline
                                            style={{
                                                borderRadius: "16px",
                                                width: "51%",
                                                height: "auto",
                                            }}
                                        />
                                    </div>
                                </div>

                                   {/* Title */}
                                   <div className="row mt-5 pt-5 mb-0">
                                    <div className="col-md-10 col-11 mx-auto text-left">
                                        <h1 className="mb-1 px-3 px-md-0 font-tertiary">Third Party Network</h1>
                                    </div>
                                </div>

                                {/* Main Description */}
                                <div className="row main-description">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                        <span className="tagline case-study mb-4">CASE STUDY</span>
                                        <p className="px-3 px-md-0">
                                            At a previous company, I collaborated with a Data Scientist on a project aimed at improving the visualization of a complex third-party network security framework. Our goal was to create a tool that would help analysts quickly identify and prioritize remediation actions among a vast amount of data. The sheer volume of data made it challenging to focus on the most critical issues. By working closely together, we developed an interactive visualization that effectively displayed third-party relationships, highlighted priority actions, and clearly indicated which issues had already been remediated.
                                        </p>
                                    </div>
                                </div>
                                  {/* Triple Text */}
                                  <div className="row triple-text my-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Tools</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>Figma/Figjam</li>
                                                    <li>ChatGPT</li>
                                                    <li>Material Design</li>
                                                    <li>D3.js</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Duration</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    3 sprints
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                {/* Business Perspective */}
                                <div className="row main-description">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                        <h3 className="text-left my-3">Business Perspective</h3>
                                        <p className="px-3 px-md-0">
                                            From a business perspective, it was crucial for the company to provide clients with a clear and intuitive tool within their SaaS product that showcased the level of risk associated with third-party vendors. Clients needed to understand at a glance which third parties required immediate attention and remediation. This feature would not only enhance the product's value proposition but also help clients manage their security posture more effectively by focusing on high-risk areas.
                                        </p>
                                    </div>
                                </div>

                                {/* User Personas */}
                                <div className="row triple-text mt-5 odd py-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3">User Personas</h3>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <h4 className="mb-2">Lucas Silva</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li><strong>Age:</strong> 28</li>
                                                    <li><strong>Location:</strong> São Paulo, Brazil</li>
                                                    <li><strong>Education:</strong> Bachelor's in Computer Science</li>
                                                    <li><strong>Job Title:</strong> Data Analyst</li>
                                                    <li><strong>Professional Challenges:</strong> Needs to sift through vast amounts of data to identify and prioritize security risks associated with third-party vendors. Requires detailed, accurate data to make informed decisions quickly.</li>
                                                    <li><strong>Personal Challenges:</strong> Feels overwhelmed by the volume of data he needs to analyze and needs to reduce stress.</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <h4 className="mb-2">Mariana Costa</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li><strong>Age:</strong> 35</li>
                                                    <li><strong>Location:</strong> Rio de Janeiro, Brazil</li>
                                                    <li><strong>Education:</strong> Master's in Information Security</li>
                                                    <li><strong>Job Title:</strong> Risk Manager</li>
                                                    <li><strong>Professional Challenges:</strong> Oversees the company's risk management strategy and needs a holistic view of network security status. Must ensure remediation actions are prioritized and executed effectively.</li>
                                                    <li><strong>Personal Challenges:</strong> Balances a demanding job with family responsibilities. Values solutions that provide clear overviews without requiring extensive time to interpret.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Methodology */}
                                <div className="row triple-text my-5 pb-4">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3">Methodology</h3>
                                        <p>
                                            My preferred method is the Double Diamond design process because it aligns perfectly with typical engineering sprints:
                                        </p>
                                        <h5 className="mt-4">Diverge - Discover</h5>
                                        <ul>
                                            <li>Data Science Collaboration</li>
                                            <li>Exploring Existing D3 Visuals</li>
                                        </ul>
                                        <h5 className="mt-4">Converge - Define</h5>
                                        <ul>
                                            <li>The Problem</li>
                                            <li>Defining Presentation Goals</li>
                                            <li>Data Requirements from Data Science</li>
                                            <li>Review and Iterate</li>
                                        </ul>
                                        <h5 className="mt-4">Diverge - Ideate</h5>
                                        <ul>
                                            <li>Sketching the Graph</li>
                                            <li>Establishing Look and Feel</li>
                                        </ul>
                                        <h5 className="mt-4">Converge - Deliver</h5>
                                        <ul>
                                            <li>Testing with Prototype Data from Data Science</li>
                                            <li>User Testing</li>
                                            <li>Review and Iterate</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Data Science Collaboration */}
                                <div className="row main-description odd mt-5 pt-5">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                        <h3 className="text-left my-3">Data Science Collaboration</h3>
                                        <p className='mb-3'>
                                            I met with the Data Science team to understand the data and assess its quality. I learned about the calculations they performed, the filtering processes, and the criteria they used to determine whether a third-party action was remediated or not. Through these collaborative sessions, I gained valuable insights into the data's structure, the algorithms used for risk assessment, and the thresholds set for prioritizing remediation actions. This understanding was critical in designing a visualization that accurately reflected the data's nuances and supported the analysts' needs.
                                        </p>
                                    </div>
                                </div>

                                {/* Exploring D3 Visuals */}
                                <div className="row triple-text pb-5 odd">
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <h3 className="text-left my-3 mt-5">Exploring D3 Visuals</h3>
                                        <p className='mb-2'>
                                            Together with the design team, we explored various visualization techniques and libraries, starting with D3.js, since it was already being used by the Data Science team to present data. We experimented with different graph layouts and interactions to find the most effective way to represent the complex network of third-party relationships and risks. This hands-on approach allowed us to quickly identify the strengths and limitations of each visualization method.
                                        </p>
                                    </div>
                                </div>

                                {/* Defining the Problem */}
                                <div className="row triple-text py-4 pb-5 mb-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3 mt-5">Defining the Problem</h3>
                                        <p>
                                            From meetings with the Product and Data Science teams, we identified the core problem: Analysts were overwhelmed by the vast amount of data related to third-party vendors, making it difficult to prioritize remediation efforts effectively. The existing tools did not provide a clear, intuitive way to visualize the network of third parties, their associated risks, and remediation statuses. This lack of clarity hindered decision-making and prolonged the remediation process.
                                        </p>
                                    </div>
                                </div>

                                {/* Presentation Goals */}
                                <div className="row main-description mb-5 pb-5">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                        <h3 className="text-left my-3">Presentation Goals</h3>
                                        <p className='mb-3'>
                                            To address this problem, we aimed to present an intuitive map of third-party vendors, focusing on those with high-priority risks. The visualization needed to allow analysts to quickly identify critical issues, view prioritization levels, and see the last checked date upon hovering over a node. Additionally, implementing click interactions would enable users to access detailed remediation actions directly from the visualization.
                                        </p>
                                    </div>
                                </div>

                                {/* Sketching the Graph */}
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <h3 className="text-left my-3 mt-5">Sketching the Graph</h3>
                                        <p className='mb-2'>
                                            We created multiple sketches of the proposed graph layouts on paper, exploring different designs and structures. These drafts were shared and discussed with the Data Science, Engineering, and Product teams to assess feasibility and gather feedback. This collaborative approach ensured that the design was not only user-friendly but also technically viable within the constraints of our platform.
                                        </p>
                                    </div>
                                </div>

                                {/* Look and Feel */}
                                <div className="row triple-text py-4 pb-5 mb-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3 mt-5">Look and Feel</h3>
                                        <p>
                                            For the visual design, we incorporated variants of the company's positive and negative colors from the existing design system. This choice helped convey the remediation status of each third-party vendor clearly—using distinct colors to differentiate between remediated and unremediated statuses. This visual cue aligned with the company's branding and made the graph more intuitive.
                                        </p>
                                    </div>
                                </div>

                                {/* Testing and Prototyping */}
                                <div className="row main-description mb-5 pb-5">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                        <h3 className="text-left my-3">Testing and Prototyping</h3>
                                        <p className='mb-3'>
                                            Testing and prototyping with data from the Data Science team proved to be challenging. However, by leveraging AI tools, we were able to generate the necessary JavaScript code to present the data effectively using D3.js. This approach accelerated the development process and allowed us to focus on refining user interactions and visual elements.
                                        </p>
                                    </div>
                                </div>

                                {/* Review and Iterate */}
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <h3 className="text-left my-3 mt-5">Review and Iterate</h3>
                                        <p className='mb-2'>
                                            After conducting user tests, we engaged in iterative refinements over three sprints. Each iteration incorporated user feedback, focusing on improving usability and addressing specific pain points. Ultimately, we arrived at a solution that tested well with users, significantly reducing the time they spent searching for remediation actions within the framework. This improved their workflow and their overall satisfaction with the tool.
                                        </p>
                                        <p>
                                            And that's all—our Third Party Network visualization reached its MVP. 
                                        </p>
                                        <p>Want more? Feel free to explore or reach out for more information.</p>
                                    </div>
                                </div>

                                {/* Related Projects */}
                                <div className="row mt-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="mb-3">Related Projects</h4>
                                        <Related
                                            title1="CYBERSECURITY DASHBOARD REDESIGN"
                                            image1="/images/challenge/proserpexa/ds1.png"
                                            type1="Portfolio"
                                            url1="Dashboard"
                                            text1="CYBERSECURITY DASHBOARD"

                                            title2="CYBERSECURITY MANAGED SERVICES"
                                            image2="/images/current/featured.png"
                                            type2="Current" 
                                            url2="Cyber"
                                            text2="ONGOING PROJECT"
                                        />
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}
export default NetworkProviderDashboard;
