import React from 'react'
import Layout from '../../layout/Layout'
import Related from '../reusable/related';


class Dalig extends React.Component{
    componentDidMount() {
        document.title = "Felixrt- Project: Dalig "; 
     }
    
    
    render(){
        return (
            <React.Fragment>
                <Layout currentPage="Portfolio">
                    <div className="portfolio dalig">
                        <section className="section">
                            <div className="container-fluid">
                                <div className="row mt-5 mb-2">
                                    <div className="col-md-10 col-11 mx-auto text-left">
                                        <h1 className="font-tertiary">DALIG</h1>
                                    </div>
                                </div>
                                {/* Main Description */}
                                <div className="row main-description">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                        <h3 className="mb-3 px-3 px-md-0">Case study</h3>
                                        <p className="px-3 px-md-0">
                                            DALIG is a mobile application that allows athletes to create and participate in competitions, upload their scores and have a rank.
                                        </p>
                                    </div>
                                </div>
                                 {/* Triple Text */}
                                 <div className="row triple-text pb-5 mb-5 mt-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Project Duration</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    4 months
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">My role</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    UX Researcher, UX Interaction Designer, UI Designer.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Responsibilities</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    User research, wireframing, prototyping, high fidelity design.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Centred picture */}
                                <div className="row py-5 centred-image odd">
                                    <div className="col-md-8 col-sm-12 mx-auto text-center">
                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/dalig-three-screens.png'} alt="Dalig Three Screens" />
                                    </div>
                                </div>
                                 {/* four texts important */}
                                 <div className="row fourthe-text-important py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row align-items-top">
                                            <div className="col-12 col-md-6">
                                                <h3 className="mb-3">The problem</h3>
                                                <p>
                                                    Athletes won't go out of their comfort zone and take their training to competition levels.
                                                    Additionally they are no aware of their progress in data.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <h3 className="mb-3">The goal</h3>
                                                <p className>
                                                    To create a solution that allows users to maintain their motivation focusing on progress, 
                                                    which will affect athletes by having a way to keep the record of their scores in competition 
                                                    using class workout scores and competition scores.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Full width picture */} 
                                <div className="row pt-5 full-width-picture">
                                    <div className="col-12 px-0">
                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/competition2.jpg'} alt="Competition" />
                                    </div>
                                </div>
                                 {/* Centred Important Description */}
                                 <div className="row centred-description-important py-5 odd">
                                    <div className="col-md-9 col-11 mx-auto text-left my-5 py-5">
                                        <h3 className="mb-3 text-center">Understanding The User</h3>
                                        <p className="px-3 px-md-0">
                                            Research was made by extracting already existing data from four crossfuntional gyms' customer database,
                                             which already had demographics and interests. From that information I assumed, for example that users,
                                             for their age and occupations, would use the app for uploading specific benchmark during class, 
                                             but with the opportunity to create private competitions.
                                        </p>
                                    </div>
                                </div>
                                  {/* Triple Text */}
                                  <div className="row triple-text py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-center my-4">Pain Points</h3>
                                        <div className="row text-center">
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h4 className="mb-4">Motivation</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    They struggled with showing up at the gym.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h4 className="mb-4">Discipline</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    They would engage in unhealthy habits outside the gym, affecting their competition performaces.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h4 className="mb-4">Tracking</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    They wouldn't know their competitive progress.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 {/* small photo descriptions */}
                                 <div className="row small-photo-description py-5 odd">
                                    <div className="col-10 mx-auto py-5">
                                        <h4 className="mb-4">User personas</h4>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-4 mb-3 mb-md-0 px-md-3 px-0">
                                                        <h5 className="mb-3">Alexon (He/Him)</h5>
                                                        <img className="portfolio-img" 
                                                            src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/alexon.png'} 
                                                            alt="Alexon"  />
                                                    </div>
                                                    <div className="col-12 col-md-3 pl-0 pl-md-3 mt-5">
                                                        
                                                        <h5 className="mb-3">Goals</h5>
                                                        <ul className="display body-2 px-0">
                                                            <li>To look good.</li>
                                                            <li>Being a competitive cross functional athlete.</li>
                                                        </ul>
                                                        <h5 className="mt-3">Frustrations</h5>
                                                        <ul className="display body-2 px-0">
                                                            <li>Not having a steady good performance at 
                                                                the gym due to diet and not enough rest.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12 col-md-3 offset-md-1 pl-0 pl-md-3 mt-5">
                                                        <h5 className="mb-3">Problem statement</h5>
                                                        <p className="display body-2">
                                                            Alexon is a competitive athlete who needs to have a better 
                                                            understanding of his competitive progress because he wants 
                                                            to increase his chances to podium on competitions.
                                                        </p>
                                                        <h5 className="mt-3">Demographics</h5>
                                                        <ul className="display body-2 px-0">
                                                            <li><strong>Age:</strong> 34</li>
                                                            <li><strong>Education:</strong> College degree</li>
                                                            <li><strong>Location:</strong> Guayaquil, Ecuador</li>
                                                            <li><strong>Family:</strong> Married, 1 children</li>
                                                            <li><strong>Occupation:</strong> Agricultural Eng.</li>    
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-12 col-md-7 pl-0 pl-md-3">
                                                        <p className="display body-2">
                                                            He spends most of his day working in the lands, making sure harvests 
                                                            go as desired. He doesn't have much time, but when he does, 
                                                            he likes being an active member of his CrossFit community, 
                                                            participating in most of the things they have to offer.
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-4 mb-3 mb-md-0 px-md-3 px-0">
                                                        <h4 className="mb-3">Laurita (She/Her)</h4>
                                                        <img className="portfolio-img" 
                                                            src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/laurita.png'} 
                                                            alt="Laurita" />
                                                    </div>
                                                    <div className="col-12 col-md-3 pl-0 pl-md-3 mt-5">
                                                        
                                                        <h5 className="mb-3">Goals</h5>
                                                        <ul className="display body-2 px-0">
                                                            <li>Maintain health.</li>
                                                            <li>Balance work-family life.</li>
                                                            <li>To look good.</li>
                                                        </ul>
                                                        <h5 className="mt-3">Frustrations</h5>
                                                        <ul className="display body-2 px-0">
                                                            <li>She cannot do all the exercises due to knee illness.</li>
                                                            <li>Some days she lacks motivation to workout.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12 col-md-3 offset-md-1 pl-0 pl-md-3 mt-5">
                                                        <h5 className="mb-3">Problem statement</h5>
                                                        <p className="display body-2">
                                                            Laurita  is masters athlete who needs to stay motivated at the gym, because she wants 
                                                            to stay healthy so she can keep being the support of her daughter.
                                                        </p>
                                                        <h5 className="mt-3">Demographics</h5>
                                                        <ul className="display body-2 px-0">
                                                            <li><strong>Age:</strong> 50</li>
                                                            <li><strong>Education:</strong> Bachelor</li>
                                                            <li><strong>Location:</strong> Salitre, EC</li>
                                                            <li><strong>Family:</strong> Single, 1 daughter</li>
                                                            <li><strong>Occupation:</strong> Restaurant owner</li>    
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-12 col-md-7 pl-0 pl-md-3">
                                                        <p className="display body-2">
                                                            She spends 6 days a week managing her restaurant which 
                                                            gives work to several people including her daughter. 
                                                            She has great friends from her day to day work and cross functional gym. 
                                                            She enjoys being an active person and works hard to look better and stay healthy.
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                {/* Centred Important Description */}
                                <div className="row centred-description-important pt-5 mt-5">
                                    <div className="col-md-10 col-sm-12 mx-auto text-left">
                                        <h3 className="mb-5 text-center">Competitive Audit</h3>
                                        <p className="px-3 px-md-0">
                                            The goal was to analyze the presentation and main features of the competition apps. 
                                            I did three of four including brands like <strong>CrossFit Games</strong> and <strong>Pukiebook</strong>, this last one 
                                            being the only direct competitor.
                                            Their main target was CrossFit athletes, but they also offered options for other sports;
                                            being their strength their detailed and variety of functionalities.
                                            The identified usability gaps were the excess of unnecessary fields on forms.
                                            What we could do better in help of the user is the accessibility, perhaps giving recommendations of mindset, 
                                            diet and weakness plans prior to a competition or class, and lastly 
                                            boost their motivation by introducing a rank game. That's why in the end I also analyzed <strong>Duolingo</strong>.
                                        </p>
                                    </div>
                                </div>
                                {/* ping pong photo descriptions */}
                                <div className="row ping-pong-photo pb-5 mb-5 pt-4">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-5 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/pukkiebook.jpg'} 
                                                    alt="Colors"/>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-5 ">
                                                
                                                <h4 className="mb-3 text-left">Pukiebook</h4>
                                                
                                                <p className="mb-5 mb-md-0">
                                                    This would be DALIG's direct competition, since it is already being used to manage 
                                                    tournament subscriptions, but it doesn't have a gamification hook.
                                                </p>
                                            </div>
                                        </div>
                                        
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-5">
                                                <h4 className="mb-3 text-left">CrossFit Games</h4>
                                                <p className="mb-5 mb-md-0">
                                                    This app was the main inspiration of DALIG,
                                                    because of how they handle their competitions
                                                    like the "CrossFit Games" and "CrossFit Open".
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-5 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/crossfitgames.jpg'} 
                                                        alt="Typography explorations"/>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 px-0 pr-md-5 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/duolingo.jpg'} 
                                                        alt="Moodboard"/>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-5">
                                                <h4 className="mb-3 text-left">Duolingo</h4>
                                                <p className="mb-5 mb-md-0">
                                                    After the gamification concept was added, I immediately thought of Duolingo, 
                                                    and how Luis von Ahn transformed "learning a language" into a fun social game.

                                                </p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                {/* Titled wide Image */}
                                <div className="row py-5 titled-wide-image odd">
                                    <div className="col-10 mx-auto text-center">
                                    <h3>Ideating:</h3>
                                    <h4 className="mb-5">Content &amp; Interaction Map</h4>
                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/sitemap.png'} alt="SiteMap" />
                                    </div>
                                </div>
                             

                            {/* four texts important */}
                            <div className="row fourthe-text-important py-5 my-5">
                                <div className="col-11 col-md-10 mx-auto">
                                    <h3 className="mb-4">Main Functionalities</h3>
                                    <div className="row align-items-top">
                                        <div className="col-12 col-md-6">
                                            <p>
                                                Athletes can create a new competition. Competitions 
                                                can have one or several events.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <p>
                                                An Athlete rank is calculated based on 
                                                leaderboards of competitions per Sport
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row align-items-top mt-2">
                                        <div className="col-12 col-md-6">
                                            <p>
                                            Events have scores, scores can be validated by judges. 
                                            Scores are also used to calculate leaderboards.
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <p>
                                                Athletes can join teams, and teams can also 
                                                have ranks by sum(athletes).
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                {/* ping pong photo descriptions */}
                                <div className="row ping-pong-photo py-5 my-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">    
                                        <div className="row">
                                            <div className="mb-4 col-12 text-center">
                                                <h3>Wireframes</h3>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/digital-wireframes1.png'} 
                                                    alt="Digital Wireframe 1"/>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                
                                                <h4 className="mb-2 text-left">Competitions and Events</h4>
                                                
                                                <p className="mb-5 mb-md-0">
                                                    The quick access to the workouts would be crucial as well as a clear call to action to the “Leaderboard” (Rank)
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-4">
                                                <h4 className="mb-2 text-left">New Competition and Leaderboard</h4>
                                                <p className="mb-5 mb-md-0">
                                                    When revising the Scores within the leaderboard, they will be able to filter by categories.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 mb-5">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/digital-wireframes2.png'} 
                                                        alt="Digital Wireframe 2"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                               


                                {/* ping pong photo descriptions */}
                                <div className="row ping-pong-photo py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                        <div className="mb-4 col-12 text-center">
                                                <h3>High Fidelity Process</h3>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/moodboard.jpg'} 
                                                        alt="Moodboard"/>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4">
                                                <h4 className="mb-2 text-left">Moodboard</h4>
                                                <p className="mb-5 mb-md-0">
                                                    When thinking about DALIG I wanted to connote vintage sports, 
                                                    so I went for old football fields and creamy military inspired uniforms from the 70s and 80s.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-4">
                                                <h4 className="mb-2 text-left">Colors</h4>
                                                <p className="mb-5 mb-md-0">
                                                    I also wanted to portray energy, and since it's a fitness app, 
                                                    I went for highly saturated almost neon greens and reds, specially for call to actions.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/colors.png'} 
                                                        alt="Typography explorations"/>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/typeface.jpg'} 
                                                    alt="Colors"/>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                
                                                <h4 className="mb-2 text-left">Typography</h4>
                                                <p className="mb-5 mb-md-0">
                                                    I wanted typefaces to be as organic as possible, and since this one was imagined to be an iOS, 
                                                    I went for “SF Pro” as the primary one and “New York” to complement it. 
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-4">
                                                <h4 className="mb-2 text-left">Logotype and Icon</h4>
                                                <p className="mb-5 mb-md-0">
                                                    This is a vector customized SF Pro. It's italic to connote movement, and the L and I 
                                                    were also modified to look like a field, which is also DALIG's icon.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/logos.png'} 
                                                        alt="Typography explorations"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Centred picture */}
                                <div className="row py-5 my-5 full-width-picture odd">
                                    <div className="col-12 text-center mt-5 mb-5">
                                        <h3 className="mb-5">High Fidelity Screens</h3>
                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/high-fi.png'} alt="High Fidelity Design" />
                                    </div>
                                </div>

                                {/* ping pong photo descriptions */}
                                <div className="row ping-pong-photo py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <h3 className="mb-4">UX Research</h3>
                                            </div>
                                        </div>
                                        <div className="row align-items-center py-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" 
                                                        src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/changes1.png'} 
                                                        alt="Changes 1" />
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4">
                                                <h4 className="mb-2 text-left">Uploading scores</h4>
                                                <p className="mb-5 mb-md-0">
                                                    When asked to upload their event score, more than half of the athletes tried to open the event 
                                                    by clicking on the description, so I used that instead of just clicking on the title.
                                                </p>
                                            </div>
                                        </div> 
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-4">
                                                <h4 className="mb-2 text-left">Overall leaderboard</h4>
                                                <p className="mb-5 mb-md-0">
                                                    A little less than three quarters of athletes didn't slide on the ranking section, 
                                                    but expected to get 
                                                    to the overall Leaderboard after clicking on it. So I changed that and added a 
                                                    few more filters to that leaderboard screen
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" 
                                                        src={ process.env.PUBLIC_URL + '/images/portfolio/dalig/changes2.png'} 
                                                        alt="Changes 2" />
                                            </div>
                                        </div> 
                                    </div>
                                </div>


                                {/* Triple Text */}
                                <div className="row triple-text">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-center my-5">Final Flow</h3>
                                        <h4 className="mb-2">Goals</h4>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12">
                                                <p className="pr-md-2 pr-sm-0">
                                                    Participate in a Competition as a CrossFit Athlete
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <p className="px-md-2 px-sm-0">
                                                    Upload your Score in one of the Events of your 
                                                    participating Competition 
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <p className="pl-md-2 ps-sm-0">
                                                    Explore the leaderboards
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row centred-description-important py-5">
                                    <div className="col-10 mx-auto text-center">
                                    <iframe
                                        width="100%" height="450" 
                                        title='Prototype'
                                        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F3FCJTFQoH5NKqVB79y4Rzb%2FDaLig-App%3Fpage-id%3D124%253A3833%26node-id%3D160%253A3977%26viewport%3D241%252C48%252C0.08%26scaling%3Dscale-down%26starting-point-node-id%3D160%253A3977" allowfullscreen>
                                </iframe>
                                    </div>
                                </div>
                                

                                 {/* Centred Important Description */}
                                 <div className="row centred-description-important py-5">
                                    <div className="col-11 col-md-10 mx-auto text-left">
                                        <h3 className="mb-3 text-center">Conclusions and Next Steps</h3>
                                        <p>
                                            This project was special to me, because it involves two of my passions: sports and design.
                                            It felt good to work together with my local CrossFit community to help them achieve their 
                                            fitness goals, while they are having fun. We are now in the developing process, 
                                            so if you are a developer with similar passions, do not hesitate in contacting
                                            me and chat about what we can do.
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="mb-3">Related Projects</h4>
                                        <Related 
                                                
                                                title1="SIMPLIFYING REQUEST TO SUPPLY"
                                                image1="/images/home/superglu2.png"
                                                url1="Pegatanke" 

                                                title2="DRONE DELIVERY APP"
                                                image2="/images/home/drotini.png"
                                                url2="DROTINI" 

                                                title3="CREATING A SOFTWARE CAD"
                                                image3="/images/home/pentacad.png"
                                                url3="Pentacad" 
                                                 
                                                 />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Layout>
            </React.Fragment>
        )
    }
}
export default Dalig;