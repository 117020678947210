import React from 'react'
import Layout from '../../layout/Layout'
import Related from '../reusable/related';


class Pegatanke extends React.Component{
    componentDidMount() {
        document.title = "Felixrt- Project: Pegatanke "; 
     }
    
    
    render(){
        return (
            <React.Fragment>
                <Layout currentPage="Portfolio">
                    <div className="portfolio superglu">
                        <section className="section">
                            <div className="container-fluid">
                                <div className="row mt-5 mb-0">
                                    <div className="col-md-10 col-11 mx-auto text-left">
                                        <h3 className="font-tertiary">Pegatanke</h3>
                                    </div>
                                </div>
                                {/* Main Description */}
                                <div className="row main-description">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                        <h1 className="mb-3 px-3 px-md-0 font-tertiary">Simplifying request to supply</h1>
                                        <p className="px-3 px-md-0">
                                            I redesigned the user interface and interaction experience of Pegatanke's website, 
                                            in order to make it easier for their end users to find, filter and supply 
                                            their products. They would become customer leads and partners, 
                                            which meets Pegatanke's business goals. 
                                            Finally, I helped them migrate web technologies from Wordpress to Webflow.
                                        </p>
                                    </div>
                                </div>
                                 {/* Triple Text */}
                                 <div className="row triple-text mb-5 mt-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Date and (Duration)</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    March 2022 (6 months)
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">My roles</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>UX Designer</li>
                                                    <li>UX Researcher</li>
                                                    <li>UI Designer</li>
                                                    <li>Visual Developer</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Responsibilities</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>User Research analysis</li>
                                                    <li>Competitive audit</li>
                                                    <li>Information Architecture</li>
                                                    <li>Wireframing and Prototyping</li>
                                                    <li>Usability study</li>
                                                    <li>High fidelity mockups</li>
                                                    <li>Webflow visual develop</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 {/* Centred picture */}
                                 <div className="row centred-image overview mb-5">
                                    <div className="col-md-11 col-sm-12 mx-auto text-center">
                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/cover.png'} alt="Pegatanke" />
                                    </div>
                                </div>
                                <div className="row triple-text pt-4 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-center my-3 mt-5">Methodology</h3>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/plan.png'} alt="Planning and methodolody" />
                                    </div>
                                   
                                </div>

                                 {/* main ping pong */}
                                 <div className="row fourthe-text-important py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 px-3 px-md-0">Understanding the problem</h3>
                                                <p className="px-3 px-md-0 mb-0">
                                                    My journey started with a meeting with Pegatanke's marketing lead, 
                                                    who asked me for help with their web channel, because they were unable to
                                                    capture a lead within the website. 
                                                    They already had a fully functional website where I observed the following insights:
                                                </p>
                                            </div>
                                        </div>
                                        {/* ping pong photo descriptions */}
                                        <div className="row ping-pong-photo pt-0 mb-5">
                                            <div className="col-12 mx-auto">

                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">Content engagement flow</h5>
                                                        <ul className="mb-5 mb-md-0">
                                                            <li>Analysis of the current site's web data; the biggest problem was bounce rate.</li>
                                                            <li>The bounce rate of the contact and supplier pages were more than 80 percent.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/engagement-flow.png'} 
                                                                    alt="Paper Wireframes"/>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 pr-md-5 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/steps-to-supply.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                        
                                                        <h5 className="mb-2 text-left">Steps to supply request</h5>
                                                        <ul className="mb-5 mb-md-0">
                                                            <li>From the home or product landing of the user, the only path to request to supply is the main menu.</li>
                                                            <li>Interaction of the menus were inconsistent. Some are clickable, some are not.</li>

                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">Form length</h5>
                                                        <p className="mb-5 mb-md-0">
                                                            Potential leads are sacrificed in order to get too much information.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/form-lenght.png'} 
                                                                    alt="Paper Wireframes"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-top">
                                            <div className="col-12 col-md-6">
                                                <h3 className="mb-3">Problem Statement</h3>
                                                <p>
                                                    Hardware store administrators, builders and suppliers were unable to complete the request of Pegatanke's products as a choice to buy or supply.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <h3 className="mb-3">Goal Statement</h3>
                                                <p>
                                                    To create a website that allows users to find different glue products 
                                                    and easily request to supply or buy.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 px-3 px-md-0">Side problems</h3>
                                                <p className="px-3 px-md-0 mb-2">
                                                    From these sessions with the marketing team, the following side problems were also identified:
                                                </p>
                                                <ul className="mb-5">
                                                    <li>Current site is hard and expensive to maintain.</li>
                                                    <li>Scalability could be an exponential issue.</li>
                                                    <li>Use of brand colors was distracting.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                 {/* Centred Important Description */}
                                 <div className="row centred-description-important py-5 odd">
                                    <div className="col-11 mx-auto">
                                        <div className="row">
                                            <div className="col-md-11 col-12 mx-auto text-left mt-5 pt-2">
                                                <h3 className="mb-4">Understanding the user</h3>
                                                <p className="px-3 px-md-0">
                                                    User research was conducted by extracting existing analytics data. Here are some of the insights I based my decisions on:
                                                </p>
                                                <ul>
                                                    <li>Most of the website acquisitions occurred after searching for <strong>specific brand products.</strong></li>
                                                    <li>Contact page was the most visited by potential leads.</li>
                                                    <li>There were also significant searches for "how to fix" and "DIY" related to glue products.</li>
                                                    <li>There were significant searches for products attached to <strong>specific locations</strong></li>
                                                    <li>The website will be mainly viewed on their desktop computer, at their office, during work time.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row small-photo-description">
                                            <div className="col-11 mx-auto pb-5 pt-3">
                                                <h4 className="mb-4">User personas</h4>
                                                <div className="row mb-3">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-12 col-md-4 mb-3 mb-md-0 px-md-3 px-0">
                                                                <h5 className="mb-3">Moisés (He/Him)</h5>
                                                                <img className="portfolio-img" 
                                                                    src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/moises.png'} 
                                                                    alt="Moises"  />
                                                            </div>
                                                            <div className="col-12 col-md-3 pl-0 pl-md-3 mt-5">
                                                                <h5 className="mb-3">Goals</h5>
                                                                <ul className="display body-2">
                                                                    <li>Maintain quality and stock of products in the hardware store.</li>
                                                                    <li>Balance work-family life.</li>
                                                                    <li>Find suppliers near.</li>
                                                                </ul>
                                                                <h5 className="mt-3">Frustrations</h5>
                                                                <ul className="display body-2">
                                                                    <li>Not having enough providers</li>
                                                                    <li>Not having product information automatically.</li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-12 col-md-3 offset-md-1 pl-0 pl-md-3 mt-5">
                                                                <h5 className="mb-3">Problem statement</h5>
                                                                <p className="display body-2">
                                                                    Moises is a Hardware store administrator who needs to have glue product 
                                                                    and distribution information, because he wants choices to maintain 
                                                                    the quality and stock of his store products.
                                                                </p>
                                                                <h5 className="mt-3">Demographics</h5>
                                                                <ul className="display body-2">
                                                                    <li><strong>Age:</strong> 49</li>
                                                                    <li><strong>Location:</strong> Lima, Peru</li>
                                                                    <li><strong>Family:</strong> Married, 2 children</li>
                                                                    <li><strong>Occupation:</strong> Administrator at a Hardware Store</li>    
                                                                </ul>
                                                            </div>
                                                        </div>
                                                 
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-12 col-md-4 mb-3 mb-md-0 px-md-3">
                                                                <h5 className="mb-3">Fabricio (He/Him)</h5>
                                                                <img className="portfolio-img" 
                                                                    src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/fabricio.png'} 
                                                                    alt="Fabricio" />
                                                            </div>
                                                            <div className="col-12 col-md-3 pl-0 pl-md-3 mt-5">
                                                                
                                                                <h5 className="mb-3">Goals</h5>
                                                                <ul className="display body-2">
                                                                    <li>Having the right tools to be competitive at work.</li>
                                                                    <li>Get enough income opportunities.</li>
                                                                </ul>
                                                                <h5 className="mt-3">Frustrations</h5>
                                                                <ul className="display body-2">
                                                                    <li>Not having a stable work routine.</li>
                                                                    <li>Not having good time management.</li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-12 col-md-3 offset-md-1 pl-0 pl-md-3 mt-5">
                                                                <h5 className="mb-3">Problem statement</h5>
                                                                <p className="display body-2">
                                                                    Fabricio is a builder who needs to buy high quality tools like glues, 
                                                                    because he wants to maintain himself up to date and being competitive at work.
                                                                </p>
                                                                <h5 className="mt-3">Demographics</h5>
                                                                <ul className="display body-2">
                                                                    <li><strong>Age:</strong> 35</li>
                                                                    <li><strong>Location:</strong> Mexico City, MX</li>
                                                                    <li><strong>Family:</strong> Single, 4 children</li>
                                                                    <li><strong>Occupation:</strong> Builder</li>    
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                  {/* Triple Text */}
                                  <div className="row triple-text py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Pain Points</h4>
                                        <div className="row text-center">
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Diversity</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    They wouldn't find different kinds of glue product options.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Product Comparison</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    They wouldn't have easy access to product categories or filters.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Location</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    They wouldn't have access to good quality glue products near their location.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 {/* small photo descriptions */}
                                 
                                {/* four texts important */}
                                <div className="row fourthe-text-important pb-5 mb-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row mb-3 text-center">
                                            <div className="col-12">
                                                <h4 className="mb-0">Potential lead journey</h4>
                                                <img className="portfolio-img mt-2" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/journey-before-awareness.png'} alt="Pegatanke's User Journey before awareness" />
                                                <img className="portfolio-img mt-3" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/journey-after-awareness.png'} alt="Pegatanke's User Journey after awareness" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 px-3 px-md-0">Action Plan</h3>
                                                <p className="px-3 px-md-0 mb-1">
                                                    After an analysis of all of the information above, we agreed to:
                                                </p>
                                                <ul className="mb-5">
                                                    <li>Re-design the responsive web interface of the whole site from scratch.</li>
                                                    <li>Using a different technology to simplify the maintenance of the site and to have longer scalability.</li>
                                                    <li>Design a <strong>user experience that prioritizes the best solution for the human</strong>.</li>
                                                    <li>Meet SEO and web semantics standards.</li>
                                                    <li>Follow Pegatanke's brand guidelines.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>


                                <div className="row fourthe-text-important py-5 my-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 px-3 px-md-0">Ideating the solution</h3>
                                                <p className="px-3 px-md-0 mb-0">
                                                    Based on the information collected above, these was the ideation process:
                                                </p>
                                            </div>
                                        </div>
                                        {/* ping pong photo descriptions */}
                                        <div className="row ping-pong-photo pt-0 mb-5">
                                            <div className="col-12 mx-auto">

                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">Brainstorming</h5>
                                                        <p className="mb-5 mb-md-0">
                                                            We did a couple of sessions of brainstorming, where the stakeholders already had a vision
                                                            for parts of the website. They would bring up several brands that already
                                                            were solving the same problem on their websites, and from these sessions, 
                                                            I got enough information to conduct a <strong>competitive audit</strong>, 
                                                            to build up the site's <strong>information architecture</strong> and
                                                            to start <strong>wireframing</strong>.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/brainstorming.png'} 
                                                                    alt="Pegatanke's Brainstorming"/>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 pr-md-5 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/competitive-audit.png'} 
                                                        alt="Pegatanke's Competitive audit visual summary"/>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                        <h5 className="mb-2 text-left">Competitive Audit</h5>
                                                        <ul className="mb-5 mb-md-0">
                                                            <li>Four brands were analyzed including direct competitors: Loctite, Gorilla Glue JB, Weld ABro.</li>
                                                            <li>They tackled mainly the US market, with significant efforts of internationalization.</li>
                                                            <li>Use of generic translations.</li>    
                                                            <li>Their main target was supplier businesses, but they also offered options to other users.</li>
                                                            <li>Almost all of these brand identities could be confused with the national USA country brand.</li>
                                                            <li>They are strong on their how-to fix and nature maintenance campaigns.</li>
                                                            <li>Articles and tutorials were outbound links.</li>
                                                            <li>There were too many steps to get to the main features.</li>
                                                            <li>They are weak on the use of effects that causes to lose focus.</li> 
                                                         </ul>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">SiteMap</h5>
                                                    <ul className="mb-5 mb-md-0">
                                                            <li>Card sorting was performed to organize the levels..</li>
                                                            <li>Home page will have peeks of all the sections.</li>
                                                           
                                                         </ul>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/sitemap.png'} alt="Sitemap"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-top">
                                            <div className="col-12 mx-auto">
                                                <h4 className="mb-2 text-center">Interaction Map</h4>
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/user-flow.png'} alt="User Flow" />
                                            </div>
                                            
                                        </div>
                                      
                                        
                                    </div>
                                </div>
                                

                                



                               

                                 {/* ping pong photo descriptions */}
                                 <div className="row ping-pong-photo pt-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row triple-text">
                                            <div className="col-11 col-md-10 mx-auto">
                                                 <h3 className="mb-2 text-center">Wireframing and Prototyping</h3>
                                                 <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/wireframing-prototyping.png'} 
                                                            alt="Wireframes and prototypes in figma"/>
                                            </div>
                                        </div>  
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-4">
                                               <h5 className="mb-2 text-left">Paper ideas</h5>
                                                <p className="mb-5 mb-md-0">
                                                    Priority screens were iterated in paper.
                                                   The supply, product listing and how-to tutorials were priority to 
                                                   meet the user needs.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/wireframes-paper.png'} 
                                                            alt="Paper Wireframes"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ping-pong-photo pb-5 mb-5">
                                    <div className="col-11 col-md-10 mx-auto">    
                                        <div className="row align-items-center mt-4">
                                            <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/digital-wireframes-1.png'} 
                                                    alt="Digital Wireframe 1"/>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                
                                            <h5 className="mb-2 text-left">Contact/Supply</h5>
                                            <p className="mb-5 mb-md-0">
                                                A "location first" approach was taken to give security to the user, that the factory 
                                                is their nearest possible, followed by a simple request form.
                                            </p>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mt-5">
                                            <div className="col-12 col-md-6 px-0 pr-md-4">
                                                <h5 className="mb-2 text-left">Product filtering</h5>
                                                <p className="mb-5 mb-md-0">
                                                    It was a pain point for the user to be able to quickly find the products, 
                                                    they can be filtered by project (industry) or (surface) material.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 mb-5">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/digital-wireframes-2.png'} 
                                                        alt="Digital Wireframe 2"/>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mt-4">
                                            <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/digital-wireframes-3.png'} 
                                                    alt="Digital Wireframe 1"/>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                
                                            <h5 className="mb-2 text-left">Video Tutorials</h5>
                                            <p className="mb-5 mb-md-0">
                                                A category page was created to organize different tutorials by project industry.
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Triple Text */}
                                <div className="row triple-text odd pt-4">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-center my-3 mt-5">Usability study</h3>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/usability.gif'} alt="Pegatanke's usability study" />
                                        <h4 className="my-2">Study details</h4>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12">
                                                <h4>Research Questions</h4>
                                                <ul className="ml-0">
                                                    <li>Is "supply a product" easy to request?</li>
                                                    <li>Are products of different use easy to find?</li>
                                                    <li>Are video tutorials easy to find?</li>
                                                    <li>Are article suggestions easy to ask?</li>
                                                    <li>Are video tutorial categories easy to find?</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4>Participants</h4>
                                                <ul>
                                                    <li>5 participants</li>
                                                    <li>Ages between 35 to 60</li>
                                                    <li>2 female, 3 male</li>
                                                    <li>Living within Ecuador.</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4>Methodology</h4>
                                                <ul>
                                                    <li>Moderated usability study</li>
                                                    <li>Remote</li>
                                                    <li>Users were asked to use a low-fidelity Figma Prototype:</li>
                                                </ul>
                                                <div className="row call-to-action mt-4">
                                            <div className="col-12 mx-auto text-left">
                                                <a className='dark btn btn-outline-secondary' href="https://www.figma.com/proto/9rIpKDn3YKnb0zux39BCxq/pegatanke?node-id=252%3A439&viewport=273%2C137%2C0.03&scaling=min-zoom&starting-point-node-id=344%3A786" target="_blank" rel="noreferrer">
                                                    LAUNCH PROTOTYPE
                                                </a>
                                            </div>
                                        </div>
                                            </div>
                                        </div>
                                        

                                    </div>
                                   
                                </div>
                                

                                {/* Triple Text */}
                                <div className="row triple-text py-5 mb-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Insights</h4>
                                        <div className="row text-center">
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Supply/Contact</h5>
                                                <p className="pr-md-2 pr-sm-0 text-left">
                                                    Users need a faster way to request for "Supply".
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Products filter</h5>
                                                <p className="pr-md-2 pr-sm-0 text-left">
                                                    Users need a “material type” filter in the products and video tutorial pages, rather than a "project" filter.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Tutorials Category Page</h5>
                                                <p className="pr-md-2 pr-sm-0 text-left">
                                                    Users do not need a video tutorial category page.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                4
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Suggest an article</h5>
                                                <p className="pr-md-2 pr-sm-0 text-left">
                                                    Users do not need “suggest an article” as a priority feature.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                 {/* Centred Important Description */}
                                 <div className="row centred-description-important py-4">
                                    <div className="col-12 mx-auto">
                                        <div className="row">
                                            <div className="col-10 mx-auto text-center">
                                                <h4 className="mb-3 text-center">Usability study takeaways</h4>
                                                <p>
                                                    Prior to moving to High Fidelity, I did the higher priority changes to the prototype and discussed it with the team.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ping-pong-photo pb-5">
                                        <div className="col-11 col-md-10 mx-auto">    
                                            <div className="row align-items-center mt-4">
                                                <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                    <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/theme1.png'} 
                                                        alt="Distribution request theme"/>
                                                </div>
                                                <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                    <div className="row mb-3 align-items-center">
                                                        <div className="col-2">
                                                            <div class="avatar avatar-sm">
                                                                <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                    1
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-10">
                                                            <h4 className="text-left mb-0">Supply Contact</h4>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <ul className="mb-5 mb-md-0">
                                                        <li>Supply form was moved to the top, so it's easier for the user to find it.</li>
                                                        <li>"Supply" will be the selected option of the motive dropdown with a different shade of color, if the user landed on the page with that intention.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row align-items-center mt-5">
                                                <div className="col-12 col-md-6 px-0 pr-md-4">
                                                    <div className="row mb-3 align-items-center">
                                                        <div className="col-2">
                                                            <div class="avatar avatar-sm">
                                                                <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                    2
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-10">
                                                            <h4 className="text-left mb-0">Products filter</h4>
                                                        </div>
                                                    </div>
                                                    <ul className="mb-5 mb-md-0">
                                                        <li>The products page now has material as the main filter, since it was the filter users looked for the most.</li>
                                                        <li>Filtering by material was also separated in two categories, so they are easier to relate.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-md-6 px-0 pl-md-4">
                                                    <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/theme2.png'} 
                                                            alt="Product filetering theme before and after"/>
                                                </div>
                                            </div>
                                            <div className="row align-items-center mt-5">
                                                <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                    <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/theme3.png'} 
                                                        alt="Digital Wireframe 1"/>
                                                </div>
                                                <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                    
                                                <div className="row mb-3 align-items-center">
                                                        <div className="col-2">
                                                            <div class="avatar avatar-sm">
                                                                <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                    3
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-10">
                                                            <h4 className="text-left mb-0">Tutorials Category Page</h4>
                                                        </div>
                                                    </div>
                                                    
                                                    <p className="mb-5 mb-md-0">
                                                        Category page was removed so the user can access the video listing faster.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row centred-description-important pb-5 odd">
                                    <div className="col-11 col-md-10 mx-auto text-left">
                                        <h3 className="mb-3 mt-5 pt-4"> Moving on to the visuals</h3>
                                        
                                        <h5>Design guidelines</h5>
                                        
                                        <p className="">
                                            We agreed to stay strict with the brand guidelines for typography and colors:
                                        </p>
                                        <img className="portfolio-img mb-4" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/brand-guidelines.png'} alt="Display of Pegatanke Brand guidelines" />
                                        <h5>Constrains</h5>
                                        <ul className="mb-4">
                                            <li>Logotype needs to be included in all the pages.</li>
                                            <li>Signature brand color needs to be used as a logotype background.</li>
                                            <li>Either can be used with a transversal cut on the left or right.</li>
                                            <li>It can also be used with the color as the background.</li>
                                            
                                        </ul>
                                        <img className="portfolio-img mb-4" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/logo-constrain.png'} alt="Logo constrains" />
                                        <div className="row align-items-center">
                                                <div className="col-12 col-md-6 px-0 pr-md-4">
                                                    <div className="row mb-3 align-items-center">
                                                       
                                                        <div className="col-10">
                                                            <h4 className="text-left mb-0">Keeping it "12-column" standard</h4>
                                                        </div>
                                                    </div>
                                                    <ul className="mb-5 mb-md-0">
                                                   
                                                            <li>In order to remain standard I decided to build the web components using Bootstrap components.</li>
                                                            <li>The 12 column grid was used to maintain layout consistency.</li>
                                                            <li>Material Design was also used in order to borrow icons and personalized buttons.</li>
                                                    
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-md-6 px-0 pl-md-4">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/12-column.png'} alt="12 column bootstrap" />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-5">
                                                <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                    <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/color-exploration.png'} 
                                                        alt="Legibility theme"/>
                                                </div>
                                                <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                    
                                                <div className="row mb-3 align-items-center">
                                                       
                                                        <div className="col-10">
                                                            <h4 className="text-left mb-0">Color explorations</h4>
                                                        </div>
                                                    </div>
                                                    
                                                    <ul className="mb-5 mb-md-0">
                                                        <li>Material shades are being used to give depth to the site.</li>
                                                        <li>Brand signature green was partitioned to complementary to get a stronger "Energy" Green.</li>
                                                        <li>Material shades were also applied to Energy Green.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                      
                                        
                                        
                                        
                                      </div>
                                    </div>
                                 {/* Centred Important Description */}
                                 <div className="row centred-description-important ">
                                    <div className="col-11 col-md-10 mx-auto text-left">
                                    <h5 className="mt-5 mb-3">Hi-Fi Screens</h5>
                                        <div className="row mt-2">
                                            <div className="col-md-6 col-12">
                                                <p className="mb-0 font-secondary">HOME AND PRODUCTS</p>
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/hifi-home.png'} alt="home screen" />
                                                
                                                <ul className="mt-4">
                                                    <li>Home presents images of a variety of ways to use Pegatanke's products, as well as the brand quality slogan.</li>
                                                    <li>Products are going to be displayed after scroll in order to give emphasis to the hero section (further testing will be needed).</li>
                                                    <li>Important sections were pulled out of the menu to always display: Products, Tutorials and Supply.</li>
                                                    
                                                </ul>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <p className="mb-0 font-secondary">REQUEST TO SUPPLY</p>
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/superglu/hifi-supply.png'} alt="supply screen" />
                                                
                                                <ul className="mt-4">
                                                    <li>Color was added to the intention select so the knows this is the main reason the request is being sent.</li>
                                                    <li>Use of brand color makes a giant cut in half to give a "strong" connotation.</li>
                                                    <li>Energy green was added to (call to action), to give emphasis.</li>
                                                </ul>
                                            </div>
                                           
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row centred-description-important pb-5">
                                    <div className="col-11 col-md-10 mx-auto text-left">
                                        <h3 className="mb-3 mt-5 pt-4 text-left">Next Steps</h3>
                                        <ul className="mt-4">
                                            <li>High fidelity screens are currently being created.</li>
                                            <li>Further testing will be completed with the developing team. </li>
                                            <li>This project is expected to be in production in december 2022</li>
                                       </ul>
                                        <h3 className="mb-3 mt-5 text-left">What did I learn from this project?</h3>
                                        <ul className="mt-4">
                                            <li>To build up research from Google Analytics.</li>
                                            <li>To stay creative with a constant background color constraint.</li>
                                            <li>To remember that good interaction is little interaction (less clicks is more). </li>
                                            
                                       </ul>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="mb-3">Related Projects</h4>
                                        <Related 
                                                
                                                title1="ADMINISTRATIVE DASHBOARD SAAS"
                                                image1="/images/home/thestralcorp.png"
                                                url1="Thestralcorp" 

                                          
                                                title2="CREATING A SOFTWARE CAD"
                                                image2="/images/home/pentacad.png"
                                                url2="Pentacad" 
                                                 
                                                 />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Layout>
            </React.Fragment>
        )
    }
}
export default Pegatanke;