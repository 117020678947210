import React from 'react'
import { Link } from "react-router-dom"

class MenuBar extends React.Component{
    render(){
        return (
            <div className="row menu-bar fixed-top py-2">
                <div className="col-md-10 mx-auto col-12">
                <header className="navigation nav-bg">
                    <nav className="navbar navbar-expand-lg navbar-light">
                       
                            <Link className="navbar-brand font-tertiary h3 p-0 m-0" to="/">
                         
                                    <h5 className="font-secondary">Felix<span className='special'>RT</span></h5>
                                
                            </Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation"
                                aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse text-center caption-text" id="navigation">
                                <ul className="navbar-nav ml-auto">
                                    <li className={'nav-item ' + (this.props.currentPage === 'Portfolio' ? 'active'  : '' ) }>
                                        <Link className="nav-link" to="/Portfolio">Portfolio</Link>
                                    </li>
                                    <li className={'nav-item ' + (this.props.currentPage === 'about' ? 'active'  : '' ) }>
                                        <Link className="nav-link" to="/About">about</Link>
                                    </li>
                                </ul>
                            </div>
                       
                    </nav>
                </header>
                </div>
            </div>
          
        )
    }
}

export default MenuBar;