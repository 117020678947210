import React from 'react'
import $ from 'jquery'
import { Link } from "react-router-dom";

const SingleRelated = ({ title, image, url, type, text }) => {
    return (
        
        <Link
            to={`/${type}/${url}`}            
        >   

              <div className="related-cover">
                <div className="title-mask align-items-center">
                    <h3 className="text-center">{text}</h3>
                </div>
                <img className="about-img-crop" src={ process.env.PUBLIC_URL + image} 
                                                    alt={title}/>
              </div>
              <p className="font-secondary text-left mt-2"> {title}</p>
            
           
        </Link>
    );
};

class Related extends React.Component {
    
    render(){
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <SingleRelated title={this.props.title1} image={this.props.image1} type={this.props.type1} text={this.props.text1} url={this.props.url1} />
                    </div>
                    <div className="col-12 col-md-4">
                        <SingleRelated title={this.props.title2} image={this.props.image2} type={this.props.type2} text={this.props.text2} url={this.props.url2} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Related;