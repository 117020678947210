import React from 'react'
import Layout from '../../layout/Layout'
import Related from '../reusable/related';


class Thestralcorp extends React.Component{
    componentDidMount() {
        document.title = "Felixrt- Project: Thestralcorp "; 
     }
    
    
    render(){
        return (
            <React.Fragment>
                <Layout currentPage="Portfolio">
                    <div className="portfolio thestralcorp">
                        <section className="section">
                            <div className="container-fluid">
                                
                                
                                
                                {/* Centred picture */}
                                <div className="row centred-image overview mb-5 cover">
                                    <div className="col-11 col-md-12 mx-auto text-center p-4 py-5 p-md-5">
                                            <img className="portfolio-img p-md-5 mx-md-4" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/big-cover.png'} alt="Thestralcorp cover" />
                                    </div>
                                </div>
                                <div className="row mt-5 pt-5 mb-0">
                                    <div className="col-md-10 col-11 mx-auto text-left">
                                        <h3 className="font-tertiary">THESTRALCORP</h3>
                                    </div>
                                </div>
                                {/* Main Description */}
                                <div className="row main-description">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                        <h1 className="mb-3 px-3 px-md-0 font-tertiary">Administrative dashboard SaaS</h1>
                                        <p className="px-3 px-md-0">
                                        I created a visual interface to optimize the experience of accountants and bureaucrats, and their clients, 
                                        as they complete their administrative tasks. I used resources like User Journey Mapping and Material 
                                        Design System to help these humans solve their main pain points.
                                        </p>
                                    </div>
                                </div>
                                 {/* Triple Text */}
                                 <div className="row triple-text mb-5 pb-5 mt-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        
                                        <div className="row">
                                           
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Responsibilities</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>User research</li>
                                                    <li>Information Architecture</li>
                                                    <li>Wireframing and Prototyping</li>
                                                    <li>Usability study</li>
                                                    <li>High fidelity mockups</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">My roles</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>UX Design</li>
                                                    <li>UX Research</li>
                                                    <li>Data Analysis</li>
                                                    <li>Visual Design</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Date and (Duration)</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    August 2022 (ongoing)
                                                </p>
                                            </div>
                                          
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <h3 className="text-left my-3 mt-5">The business perspective</h3>
                                        <p>
                                            It all started with a conversation with the CEO of Thestralcorp in New Jersey. 
                                            She wanted to redesign their marketing website, where clients would start their journey
                                            sending a service request through the website's 
                                            form. Then, they would continue via phone call, email messages or visiting the company's office.
                                            The requirement was to make the website look more modern and "technologic".
                                            After a few sessions, we agreed to additionally create an 
                                            Administration Dashboard, where clients would be able to track the status of their services, 
                                            and employees would be able to create and schedule them.
                                            
                                        </p>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/brainstorm.png'} alt="Planning and methodolody" />
                                    </div>
                                </div>

                                <div className="row triple-text pt-4">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3 mt-5">User research</h3>
                                        <p>
                                            In order to understand the end users, I interviewed several clients and 
                                            employees, and I got to synthesize a qualitative data with the intention to clarify the following:
                                        </p>
                                        <ul className="mb-5">
                                            <li>Where do they use the service?</li>
                                            <li>Why do they use the service?</li>
                                            <li>When do they use the service?</li>
                                            <li>The process of getting a task done.</li>
                                            <li>Where are they from?</li>
                                            <li>Why Thestralcorp?</li>
                                        </ul>
                                        <h4 className="text-left my-2 mt-5">Empathy first!</h4>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/empathy-map-1.png'} alt="Planning and methodolody" />
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/empathy-map-2.png'} alt="Planning and methodolody" />
                                        <h3 className="text-left my-3 mt-5">Who are they?</h3>
                                        <p>
                                            From these interviews I figured that most of them were immigrant 
                                            latinos that worked several hours a day and needed help with their paperwork. 
                                            Similarly the employees were immigrants or first generation, who wanted to help from their experience.
                                        </p>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/persona-1.png'} alt="Planning and methodolody" />
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/persona-2.png'} alt="Planning and methodolody" />
                                    </div>
                                </div>
                                {/* Triple Text */}
                                <div className="row triple-text py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Pain Points</h4>
                                        <div className="row text-center">
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Time management</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    The most important one, we would like to minimize their interaction while achieving their goals.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Organization</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Some of their paperwork would get lost on their email threads.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Service definition</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Some of the services were not clear.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                4
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Opportunity</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    They usually don't know what their options are when doing paperwork.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3 mt-5">The problem</h3>
                                        <p>
                                            Thestralcorp users need a functional online application that provides well defined services, 
                                            organization and instant updates of their tasks or paperwork status, 
                                            because they are busy in work and they don't know most of these processes.
                                        </p>
                                        <h4 className="text-left my-2 mt-5">Methodology</h4>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/methodology.png'} alt="Planning and methodolody" />
                                        <h3 className="mt-4 mb-2 text-left">Action Plan</h3>
                                        <ul className="mb-5">
                                            <li>To rebuild their website with clear service definitions and translation to Spanish.</li>
                                            <li>To create a web application with user roles where</li>
                                            <li>Administrators could easily manage clients, their services and update these statuses in an organized way.</li>
                                            <li>Clients could see their services and see their current status at any time.</li>
                                            <li>To use an agile approach to Design Thinking to see weekly progress.</li>
                                            <li>To create a Design and components system for future use.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row triple-text py-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-2 mt-5">Sprinting: Ready? Set, Go!</h3>
                                        <h4 className="text-left my-3 mt-2">What are the main screens? How might we start?</h4>
                                        <p>
                                            In order to understand the process both user personas would go in a 
                                            typical day of service transaction, I built the following journeys using 
                                            the information we already had collected:
                                        </p>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/journey-1.png'} alt="Planning and methodolody" />
                                        <img className="portfolio-img mb-3" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/journey-2.png'} alt="Planning and methodolody" />
                                        <h3 className="mb-3 mt-4 text-left">What did the journeys tell us?</h3>
                                        <p className='mb-2'>Priority user stories to address their pain points are:</p>
                                        <ul className="mb-5">
                                            <li>Administrators want a dashboard with all the current active services for clients so they could have them at hand.</li>
                                            <li>Administrators want an indicator of which services need immediate attention so they know what their current priority is.</li>
                                            <li>Administrators want a summary of dates, locations, client, service and steps at hand so they can take informed decisions.</li>
                                            <li>Administrators want to see preferred call times when user input is required so they can schedule a convenient time.</li>
                                            <li>Clients want to know specifications of the service so they know what they can ask for.</li>
                                            <li>Clients need to have different views of their services: location so they would need to be present, calendar so they know when they are going to be needed.</li>
                                            <li>Clients need to know the responsible of their service so they know who will be handling the information submitted.</li>
                                            <li>Clients need to know the progress and information of the progress of their services so they know what is already done and what is missing.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3 mt-5">Information architecture</h3>
                                        <p>
                                            From the user journey mapping I built the following site and content map starting with the main website:
                                        </p>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/information-architecture.png'} alt="Planning and methodolody" />
                                    </div>
                                </div>


                                    
                                 {/* main ping pong */}
                                 <div className="row fourthe-text-important py-4 my-4 mt-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12">
                                                <h3 className="text-left mb-2 mt-5">File request step</h3>
                                                <p>
                                                    Since the main journey for both employees and clients are similar and it goes around finding or 
                                                    creating a service, then based on the information about the requirement, they would edit or create 
                                                    a service step (task). And that repeated until a finite number of steps are finished. 
                                                    From now on, I will show you on my design process for this specific case scenario, 
                                                    which is similar for the other services.
                                                </p>
                                                <img className="portfolio-img my-4 pb-4" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/user-flow.png'} alt="Planning and methodolody" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <h3 className="mb-2">Wireframes</h3>
                                                <p className="mb-0">
                                                    Here are three of the main screens from user flow:
                                                </p>
                                            </div>
                                        </div>
                                        {/* ping pong photo descriptions */}
                                        <div className="row ping-pong-photo pt-0 mb-5">
                                            <div className="col-12 mx-auto">

                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                        <h5 className="mb-2 text-left">Steps list</h5>
                                                        <p className="mb-1">
                                                            The most important screen is the List Steps view of a Service, 
                                                            so the user and the administrator knows exactly where, when and how are the task taking place.
                                                            Note that the client and step informantion are compared side to side to garantee an informed next action.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/THESTRALCORP/paper-wireframe-1.png'} 
                                                                    alt="Paper Wireframes"/>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/THESTRALCORP/paper-wireframe-3.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        
                                                        <h5 className="mb-2 text-left">Steps, Map and Calendar view</h5>
                                                        <p className="mb-1">
                                                            Three main views were ideated in order to give the user all the resources in time and space to finish their tasks.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">Service List</h5>
                                                        <p className="mb-1">
                                                            As seen on the user journey: client, progress and deadlines are priority information for both user personas. So the service list is ideated to present them on the front.
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6 mt-4 mt-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/THESTRALCORP/paper-wireframe-2.png'} 
                                                                    alt="Paper Wireframes"/>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/THESTRALCORP/digital-wireframe.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        
                                                        <h5 className="mb-2 text-left">Grounding to components</h5>
                                                        <p className="mb-1">
                                                            With the digital wireframes, I wanted to use components of a typical dashboard app, 
                                                            so I went for resources that can be found in Google's Material Design.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3 mt-5">Visual process: Moodboard</h3>
                                        <p>
                                            In order to portray the technological “Next era” of accounting services wanted, I discussed a couple of moodboards and we agreed to go with the following:
                                        </p>
                                        <img className="portfolio-img my-2" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/moodboard.png'} alt="Planning and methodolody" />
                                        <h3 className="mb-2 mt-4 text-left">Material Design and Tailwind</h3>
                                        <p>
                                            In order to accelerate the development of the product, we agreed to remain standard, using styles and 
                                            components inherited from Google's material design and components from Tailwind framework.
                                        </p>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/design-choices.png'} alt="Planning and methodolody" />
                                        <h3 className="mb-2 mt-3 text-left">Dark Mode first</h3>
                                        <ul>
                                            <li>We agreed to work in Dark mode first to remain consistent with the 
                                            look and feel the brand, since they were already working Dark Mode first.</li>
                                            <li>Must of the users will use this app within an office, on a desktop computer with access to Wifi.</li>
                                        </ul>
                                        <h4 className="mb-2 mt-4 text-left">Mid Fidelity Screen: Steps List</h4>
                                        <p>
                                            This was the first approach to the Tasks(Steps) List where users will have 
                                            a table with the required information so they can manage their clients' services.
                                        </p>
                                        <img className="portfolio-img my-3 mb-5" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/dark-mode.png'} alt="Planning and methodolody" />
                                    </div>
                                </div>

                                {/* Triple Text */}
                                <div className="row triple-text pt-4">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3 mt-5">Usability study</h3>
                                        <h4 className="my-2">Study details</h4>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12">
                                                <h4>Research Questions</h4>
                                                <ul className="ml-0">
                                                    <li>Is “Add row” a good name for new steps addition?</li>
                                                    <li>Are users able to switch steps views?</li>
                                                    <li>Do users understand live tracking?</li>
                                                    <li>How long does it take for a user to add a new step?</li>
                                                    <li>Do users find helpful the right information section?</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4>Participants</h4>
                                                <ul>
                                                    <li>3 female, 2 male.</li>
                                                    <li>Ages around 36 years old.</li>
                                                    <li>Living within the USA.</li>
                                                    <li>3 with vision limitations.</li>
                                                    <li>3 lived in big cities.</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4>Methodology</h4>
                                                <ul>
                                                    <li>Moderated usability study</li>
                                                    <li>Remote</li>
                                                    <li>Users were asked to use a low-fidelity Figma Prototype</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Triple Text */}
                                <div className="row triple-text py-5 mb-5 ">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Insights</h4>
                                        <div className="row text-center">
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">New Steps</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    New steps interaction at the bottom of the step table would create too many errors.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Progress</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    Users prefer steps completed to percentage progress.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Sorting</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    Steps numbers are confusing when sorting.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                4
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Tabs</h5>
                                                <p className="pr-md-2 pr-sm-0 text-left">
                                                    Users would naturally search the calendar view of the steps on the menu instead of the tabs.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-12">
                                                <h3 className="mb-2 text-left">Usability study takeaways</h3>
                                                <p className='mb-2'>Prior to moving to the High Fidelity, I did the following priority changes to the prototype and discussed it with the team:</p>
                                                <ul className="mb-1">
                                                    <li>New step at the bottom could be changed to Add step instead of new row, and a form must be presented so not technical users are not forced to use the table to edit.</li>
                                                    <li>Number of steps completed is preferred when talking about progress</li>
                                                    <li>Steps number should be deleted so the user knows they are able to finish any step that is not next in the succession</li>
                                                    <li>“Days” should be the calendar default.</li>
                                                    <li>Tabs should be moved to menu.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-2 mt-5">Redesigning the marketing website</h3>
                                        <p>
                                            I transformed the basic one page contact form website to a full marketing website using webflow.
                                        </p>
                                        <img className="portfolio-img my-2" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/webflow-marketing-site.png'} alt="Planning and methodolody" />
                                        <div className="row call-to-action my-4">
                                            <div className="col-12 mx-auto text-center">
                                                <a className='dark btn btn-outline-secondary' href="https://thestral-corp-2.webflow.io/es" target="_blank" rel="noreferrer">
                                                    LAUNCH WEBSITE
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row triple-text py-5 ">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-4 mt-5">High Fidelity Screens</h3>
                                        <h4>Main Calendar View</h4>
                                        <p>
                                            Users can view their tasks for an specific day, defaulted for the current day.
                                        </p>
                                        <img className="portfolio-img my-1 mb-4" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/hi-fi/main-calendar-view.png'} alt="Planning and methodolody" />
                                        <h4 className="mb-2 mt-4 text-left">My services</h4>
                                        <p>
                                            Users can see a list of their active services.
                                        </p>
                                        <img className="portfolio-img my-1 mb-4" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/hi-fi/my-services.png'} alt="Planning and methodolody" />
                                        <h4 className="mb-2 mt-4 text-left">Add/Edit step</h4>
                                        <p>
                                            Users can save a new step, or discard.
                                        </p>
                                        <img className="portfolio-img my-1 mb-4" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/hi-fi/add-edit-step.png'} alt="Planning and methodolody" />
                                        <h4 className="mb-2 mt-4 text-left">Week Calendar view</h4>
                                        <p>
                                            Users can see week view of their tasks.
                                        </p>
                                        <img className="portfolio-img my-1 mb-4" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/hi-fi/week-view.png'} alt="Planning and methodolody" />
                                        <h4 className="mb-2  mt-4 text-left">Steps list view</h4>
                                        <p>
                                            A refinement of the main list view of steps where users can see a list of their
                                            tasks for that service with the relevant information at hand like: Client, description, progress, responsible, deadlines and locations.
                                        </p>
                                        <img className="portfolio-img my-1 mb-4" src={ process.env.PUBLIC_URL + '/images/portfolio/thestralcorp/refinement.png'} alt="Planning and methodolody" />
                                    </div>
                                </div>

                                
                                
                                 {/* Triple Text */}
                                 <div className="row triple-text py-5 mb-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Accessibility considerations</h4>
                                        <div className="row text-center mb-4">
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Vertical Rythm</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Screens are readable from top to bottom by a screen reader.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Navigation Icons</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Used icons to help users understand steps and services easier.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Color-blind-proof</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    All the colors were contrast and color blind tested.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    
                                <div className="row centred-description-important pb-5">
                                    <div className="col-11 col-md-10 mx-auto text-left">
                                        <h3 className="mb-3 mt-5 text-left">What did I learn from this project?</h3>
                                        <ul className="mt-4">
                                            <li>There is a lot of life improvement we can give people with technology and automatic information. 
                                                It is our responsibility to be out there creating for people, because we all know there is always somebody to help.</li>
                                            <li>A Dark theme is received with naturality without relevant issues.</li>
                                       </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="mb-3">Related Projects</h4>
                                        <Related 
                                                
                                                title1="CYBERSECURITY MANAGED SERVICE"
                                                image1="/images/current/featured.png"
                                                type1="Current" 
                                                url1="Cyber"
                                                text1="ONGOING PROJECT"

                                                title2="SALARY COMPARISON TOOL"
                                                image2="/images/challenge/xcorp/feature.png"
                                                type2="Challenge" 
                                                text2="CHALLENGE"
                                                url2="Xcorp"
                                                 
                                                 />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Layout>
            </React.Fragment>
        )
    }
}
export default Thestralcorp;