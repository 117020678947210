import React from 'react'
import Layout from '../../layout/Layout'
import Related from '../reusable/related';


class DROTINI extends React.Component{
    componentDidMount() {
        document.title = "Felixrt- Project: DROTINI "; 
     }
    
    
    render(){
        return (
            <React.Fragment>
                <Layout currentPage="Portfolio">
                    <div className="portfolio drotini">
                        <section className="section">
                            <div className="container-fluid">
                                <div className="row mt-5 mb-0">
                                    <div className="col-md-10 col-11 mx-auto text-left">
                                        <h3 className="font-tertiary">DROTINI</h3>
                                    </div>
                                </div>
                                {/* Main Description */}
                                <div className="row main-description">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                        <h1 className="mb-3 px-3 px-md-0 font-tertiary">Drone delivery app</h1>
                                        <p className="px-3 px-md-0">
                                            I created a user experience and mobile app interface to help people 
                                            buy and track packages delivered by drones.
                                            I took design thinking resources to make sure users feel confident and secure 
                                            that they will get
                                            non-contact, fast and disinfected packages to any destination. 

                                        </p>
                                    </div>
                                </div>
                                {/* Centred picture */}
                                <div className="row centred-image overview my-4 mb-5">
                                        <div className="col-11 col-md-12 mx-auto text-center">
                                            <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/cover.png'} alt="DROTINI" />
                                    </div>
                                </div>
                                 {/* Triple Text */}
                                 <div className="row triple-text mb-5 mt-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        
                                        <div className="row">
                                           
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Responsibilities</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>User research</li>
                                                    <li>Competitive audit</li>
                                                    <li>Information Architecture</li>
                                                    <li>Wireframing and Prototyping</li>
                                                    <li>Usability study</li>
                                                    <li>High fidelity mockups</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">My roles</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>UX Designer</li>
                                                    <li>UX Researcher</li>
                                                    <li>UI Designer</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Date and (Duration)</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    September 2021 (5 months)
                                                </p>
                                            </div>
                                          
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row triple-text pt-4 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-center my-3 mt-5">Methodology</h3>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/plan2.png'} alt="Planning and methodolody" />
                                    </div>
                                </div>

                                 {/* main ping pong */}
                                 <div className="row fourthe-text-important py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 px-3 px-md-0">Understanding the problem</h3>
                                                <p className="px-3 px-md-0 mb-0">
                                                    My journey started with the client's idea of creating a safer way to get deliveries
                                                    during the COVID-19 pandemic. But it can also solve
                                                    no-contact, limited time, or ground transportation challenging deliveries.
                                                    Since, there were limited data or budget to research,
                                                    I started to deepen my understanding of
                                                    the problem with a benchmark with the <strong>hypothesis</strong> that there
                                                    must be other apps doing delivery by drones:

                                                </p>
                                            </div>
                                        </div>
                                        {/* ping pong photo descriptions */}
                                        <div className="row ping-pong-photo pt-0 mb-5">
                                            <div className="col-12 mx-auto">

                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">Benchmark: Who is already flying out there?</h5>
                                                    <p className="mb-1">
                                                    I conducted an audit on drone delivery apps: Wing(Google), Flytrex, Manna and Zipline, 
                                                    to answer to the following questions:
                                                    </p>
                                                    <ul className="mb-5 mb-md-0">
                                                        
                                                        <li>What is the voice, tone and look-and-feel of their solutions?</li>
                                                        <li>What is their content like?</li>
                                                        <li>What is the user flow?</li>
                                                        <li>How far are they flying?</li>
                                                        <li>Where are they flying and up to how long?</li>
                                                        <li>How fast can they go?</li>
                                                        <li>What opportunities do we have to make a better solution?</li>
                                                    </ul>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/competitive-audit.png'} 
                                                                    alt="Paper Wireframes"/>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 pr-md-5 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/learn-from-competitive-audit.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                        
                                                        <h5 className="mb-2 text-left">What did I learn from the audit?</h5>
                                                        <ul className="mb-5 mb-md-0">
                                                            <li>Prices were perceived as moderate to expensive for the whole service.</li>
                                                            <li>Package sizes would be up to 25cmx25cm.</li>
                                                            <li>Most of these services would only deliver food.</li>
                                                            <li>Two of them put emphasis on <strong>medicine</strong> delivery.</li>
                                                            <li>The biggest two of these businesses <strong>changed their model</strong> to focus on drone technology as a service.</li>
                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">What can they do better?</h5>
                                                    <ul className="mb-5 mb-md-0">
                                                            <li>They need to work on usability, feedback and deadends.</li>
                                                            <li>Brand identity is poor.</li>
                                                            <li>Wings transitions are too slow</li>
                                                            <li>They need to work on their delivery points.</li>
                                                            <li>The variety of products is poor.</li>
                                                            <li>Accessibility is not existent.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/amazon.jpg'} 
                                                                    alt="Paper Wireframes"/>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 pr-md-5 mb-5 mb-md-0">
                                                        <div class="video-wrapper">
                                                            <iframe src="https://www.youtube.com/embed/3bDyeUiWL3M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                        
                                                        <h5 className="mb-2 text-left">Amazon's drones are struggling</h5>
                                                        <p className="mb-5 mb-md-0">
                                                           Amazon stopped their drone delivery service to work on their own drones. 
                                                           This was after a few accidents happened to their drones
                                                           during product shipping.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-top">
                                            <div className="col-12 col-md-6">
                                                <h3 className="mb-3">Problem Statement</h3>
                                                <p>
                                                    People who have risky medical conditions who have
                                                    to lessen their human contact, and people living away from cities,
                                                    need a way to get the same delivery products as everyone else.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <h3 className="mb-3">Goal Statement</h3>
                                                <p>
                                                    To create an app that helps people with risky medical conditions or
                                                    living in difficult locations, by offering 
                                                    a user experience and interface that gives them security
                                                    that they would get a non-contact, fast and disinfected delivery.

                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12 col-md-6">
                                                <h3 className="mb-3 px-3 px-md-0">What can we help with?</h3>
                                                <p className="px-3 px-md-0 mb-2">
                                                    From these audits, a couple of opportunities were seen:
                                                </p>
                                                <ul className="mb-5">
                                                    <li>Big brands like Amazon's PrimeAir and Google's Wing are betting for this kind of industry.</li>
                                                    <li>Since at the moment, these businesses are still exploring solutions, it could be a good time to get experimental.</li>
                                                    <li>As solutions are still being iterated by other companies, there will be new problems, 
                                                        hence new audits may be needed periodically.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                 {/* Centred Important Description */}
                                 <div className="row centred-description-important py-5 odd">
                                    <div className="col-11 mx-auto">
                                        <div className="row">
                                            <div className="col-md-11 col-12 mx-auto text-left mt-5 pt-2">
                                                <h3 className="mb-4">Understanding the user</h3>
                                                <p className="px-3 px-md-0">
                                                    I extracted the data from the current pool of our competitor's clients, using the same brands I tested 
                                                    for the benchmark, with the following questions:
                                                </p>
                                                <li>Who are they selling this service to?</li>
                                                <li>What are they commenting in the reviews section?</li>
                                                <li>What are their likes, wants and frustrations?</li>
                                            </div>
                                        </div>
                                        <div className="row small-photo-description">
                                            <div className="col-11 mx-auto pb-5 pt-3">
                                                <h4 className="my-4">Who are they?</h4>
                                                <div className="row mb-3">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-12 col-md-4 mb-3 mb-md-0 px-md-3 px-0">
                                                                <h5 className="mb-3">Sofia (She/Her)</h5>
                                                                <img className="portfolio-img" 
                                                                    src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/sofia.png'} 
                                                                    alt="Moises"  />
                                                            </div>
                                                            <div className="col-12 col-md-3 pl-0 pl-md-3 mt-5">
                                                                <h5 className="mb-3">Goals</h5>
                                                                <ul className="display body-2">
                                                                    <li>To get medical attention with minimal human interaction</li>
                                                                    <li>To take care of her parents</li>
                                                                </ul>
                                                                <h5 className="mt-3">Frustrations</h5>
                                                                <ul className="display body-2">
                                                                    <li>Sometimes I do not trust sellers or shipping businesses.</li>
                                                                    <li>COVID-19 pandemic makes it hard to deliver important medicines.</li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-12 col-md-3 offset-md-1 pl-0 pl-md-3 mt-5">
                                                                <h5 className="mb-3">Problem statement</h5>
                                                                <p className="display body-2">
                                                                    Sofia is a person with a risky medical condition who needs to receive 
                                                                    medical services with minimal human interaction, 
                                                                    because she is vulnerable to strong viruses.

                                                                </p>
                                                                <h5 className="mt-3">Demographics</h5>
                                                                <ul className="display body-2">
                                                                    <li><strong>Age:</strong> 27</li>
                                                                    <li><strong>Location:</strong> Quito, Ecuador</li>
                                                                    <li><strong>Family:</strong> Single, lives with parents</li>
                                                                    <li><strong>Occupation:</strong> Customer service</li>    
                                                                </ul>
                                                            </div>
                                                        </div>
                                                 
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-12 col-md-4 mb-3 mb-md-0 px-md-3">
                                                                <h5 className="mb-3">Daryl(They/Them)</h5>
                                                                <img className="portfolio-img" 
                                                                    src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/daryl.png'} 
                                                                    alt="Fabricio" />
                                                            </div>
                                                            <div className="col-12 col-md-3 pl-0 pl-md-3 mt-5">
                                                                
                                                                <h5 className="mb-3">Goals</h5>
                                                                <ul className="display body-2">
                                                                    <li>Have a quiet life.</li>
                                                                    <li>Enjoy favorite meals</li>
                                                                    <li>Maximize time with family.</li>
                                                                </ul>
                                                                <h5 className="mt-3">Frustrations</h5>
                                                                <ul className="display body-2">
                                                                    <li>Living 1h from the city and having to drive 2h just to pick up things.</li>
                                                                    <li>Waiting for delivery people to reach the house, if delivery is even available.</li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-12 col-md-3 offset-md-1 pl-0 pl-md-3 mt-5">
                                                                <h5 className="mb-3">Problem statement</h5>
                                                                <p className="display body-2">
                                                                        Daryl is a Professor who lives in a small town who needs to enjoy city products 
                                                                        because they want to stay in their town, but to have some of the city's comforts.
                                                                </p>
                                                                <h5 className="mt-3">Demographics</h5>
                                                                <ul className="display body-2">
                                                                    <li><strong>Age:</strong> 45</li>
                                                                    <li><strong>Location:</strong> Daule, Ecuador</li>
                                                                    <li><strong>Family:</strong> Married, 0 children</li>
                                                                    <li><strong>Occupation:</strong> Professor</li>    
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                  {/* Triple Text */}
                                  <div className="row triple-text py-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Pain Points</h4>
                                        <div className="row text-center">
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Security</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    They would like to know their package would reach its destination harmless and at time.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Safety</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    They worry about their safety when getting certain products to their houses.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Economy</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    They are worried about the cost of getting certain products to their location.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 {/* small photo descriptions */}
                                 
                                {/* four texts important */}
                                <div className="row fourthe-text-important mb-5 pb-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row mb-5 text-center">
                                            <div className="col-12">
                                                <h4 className="mb-5">User journey</h4>
                                                <img className="portfolio-img mt-2" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/user-journey.png'} alt="DROTINI's User Journey before awareness" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 px-3 px-md-0">Action plan</h3>
                                                <p className="px-3 px-md-0 mb-1">
                                                    After an analysis of all of the information above, we agreed to design an app with the following scope:
                                                </p>
                                                <ul className="">
                                                    <li>App to see, buy and drone ship products from a Business to an end User.</li>
                                                    <li>App ability to know the status of delivery.</li>
                                                    <li>App must show the drone position at all times during delivery.</li>
                                                    <li>Create a strong brand identity with a clear unique value proposition.</li>
                                                    <li>Follow the design thinking process to ideate, create, test and iterate this particular delivery solution.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>


                                <div className="row fourthe-text-important py-5 my-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12 col-md-10">
                                                <h3 className="mb-3 mt-5 px-3 px-md-0">Ideating the solution</h3>
                                                <p className="px-3 px-md-0 mb-0">
                                                    Based on the information collected above, this was the ideation process:
                                                </p>
                                            </div>
                                        </div>
                                        {/* ping pong photo descriptions */}
                                        <div className="row ping-pong-photo pt-0 mb-5">
                                            <div className="col-12 mx-auto">

                                                <div className="row align-items-center mt-5">
                                                    <div className="col-12 col-md-6 pr-md-4">
                                                    <h5 className="mb-2 text-left">Brainstorming</h5>
                                                        <p className="mb-5 mb-md-0">
                                                            We started ideating with a session of brainstorming where we
                                                            put out ideas on 
                                                            what sections and content were going to be displayed.
                                                            During this session, there were misunderstandings 
                                                            about the drone delivery scope, so a big picture storyboard was created.
                                                            .
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-2 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/brainstorm.png'} 
                                                                    alt="Pegatanke's Brainstorming"/>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 pr-md-5 mb-5 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/big-picture.png'} 
                                                        alt="Pegatanke's Competitive audit visual summary"/>
                                                    </div>
                                                    <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                        <h5 className="mb-2 text-left">The big picture</h5>
                                                        <ul className="mb-5 mb-md-0">
                                                            <li>The story starts with the user wanting medicine in a place that seems away from the city.</li>
                                                            <li>User uses DROTINI and orders his medicine.</li>
                                                            <li>After purchasing, the user seems to be tracking the delivery.</li>    
                                                            <li>Finally the user sees the drone approaching their backyard.</li>
                                                            <li>User sees a package being dropped from the drone.</li>
                                                            <li>They are finally happy because they have the medicine now.</li>
                                                         </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-top">
                                            <div className="col-12">
                                                <h4 className="mb-2">User flow</h4>
                                                <div className="row">
                                                    <div className="col-12 col-md-10">
                                                        <p className="mb-5 mb-md-0">
                                                                    From any state of the app, users will be able to choose a product catalog,
                                                                    a single product, tracking information, shipping location or a how to use tutorial.
                                                        </p>
                                                    </div>
                                                </div>
                                                <img className="portfolio-img my-4" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/user-flow2.png'} alt="User flow"/>
                                            </div>
                                            
                                        </div>
                                      
                                        
                                    </div>
                                </div>
                                

                                



                               

                                 {/* ping pong photo descriptions */}
                                 <div className="row ping-pong-photo pt-5 my-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row triple-text">
                                            <div className="col-11 col-md-10 mx-auto">
                                                 <h3 className="mb-4 text-center">low-fi wireframes and prototypes</h3>
                                                 <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/prototypev1.png'} 
                                                            alt="FIrst Prototype of DROTINI"/>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <div className="row ping-pong-photo pb-5 mb-5">
                                    <div className="col-11 col-md-10 mx-auto">    
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/live-tracking.png'} 
                                                    alt="Digital Wireframe 1"/>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                <h5 className="mb-2 text-left">The tracking map</h5>
                                                <p className="mb-3 mb-md-0">
                                                    One of the main features possible with drones was the ability to use the gps so the user knows 
                                                    exactly where their order is at any time.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 px-0 pr-md-4">
                                                <h5 className="mb-2 text-left">Geolocated cart</h5>
                                                <p className="mb-5 mb-md-0">
                                                    The idea is to combine the cart screen with the shipping map,
                                                    so the user is always aware of the delivery time and 
                                                    locations of the products, before purchase.
                                                </p>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 mb-5">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/geolocated-cart.png'} 
                                                        alt="Digital Wireframe 2"/>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/home.png'} 
                                                    alt="Digital Wireframe 3"/>
                                            </div>
                                            <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                
                                            <h5 className="mb-2 text-left">Home</h5>
                                            <p className="mb-5 mb-md-0">
                                               The main screen, where all will start will be the home, 
                                               and it will display the catalog of products
                                               categorized by industry.
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Triple Text */}
                                <div className="row triple-text odd pt-4">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-center my-3 mt-5">Usability study</h3>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/drotini-usability.gif'} alt="DROTINI's usability peek" />
                                        <h4 className="my-2">Study details</h4>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12">
                                                <h4>Research Questions</h4>
                                                <ul className="ml-0">
                                                    <li>Is purchase flow easy to follow?</li>
                                                    <li>Are users able to complete the purchases?</li>
                                                    <li>Do users understand live tracking?</li>
                                                    <li>How long does it take for a user to click on live tracking?</li>
                                                    <li>Do users understand the concept of business categories , and products?</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4>Participants</h4>
                                                <ul>
                                                    <li>3 female, 2 male.</li>
                                                    <li>Ages around 33 years old.</li>
                                                    <li>Living within the USA.</li>
                                                    <li>3 with vision limitations.</li>
                                                    <li>3 lived in big cities.</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4>Methodology</h4>
                                                <ul>
                                                    <li>Moderated usability study</li>
                                                    <li>Remote</li>
                                                    <li>Users were asked to use a low-fidelity Figma Prototype:</li>
                                                </ul>
                                                <div className="row call-to-action mt-4">
                                            <div className="col-12 mx-auto text-left">
                                                <a className='dark btn btn-outline-secondary' href="https://www.figma.com/proto/Oky2PjyH92He0T9wvv6lhj/Drotini?node-id=31%3A4277&viewport=152%2C648%2C0.21&scaling=min-zoom&starting-point-node-id=31%3A4278" target="_blank" rel="noreferrer">
                                                    LAUNCH PROTOTYPE
                                                </a>
                                            </div>
                                        </div>
                                            </div>
                                        </div>
                                        

                                    </div>
                                   
                                </div>
                                

                                {/* Triple Text */}
                                <div className="row triple-text py-5 mb-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Insights</h4>
                                        <div className="row text-center">
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Security</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    Users needed more information about who controls the drones?, other flight status and weather conditions of the route.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Understanding</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    How to start the process at home screen can be more intuitive.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Legibility</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    They need better reading font size.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                4
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Financial security</h5>
                                                <p className="pr-md-2 pr-sm-0 text-left">
                                                    "What is my order number or receipt?", "Are these pre-tax prices?"
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                 {/* Centred Important Description */}
                                 <div className="row centred-description-important py-4">
                                    <div className="col-12 mx-auto">
                                        <div className="row">
                                            <div className="col-10 mx-auto text-center">
                                                <h4 className="mb-3 text-center">Usability study takeaways</h4>
                                                <p className="mb-3 text-left">
                                                    Prior to moving to the High Fidelity, I did the following priority changes to the prototype and discussed it with the team:
                                                </p>
                                                <ul className="mb-3 text-left">
                                                    <li>Text must follow standard style guides.</li>
                                                    <li>Add recurrent notifications of the drone and package status.</li>
                                                    <li>Add a brief description of the operator, connect it with the report a problem button.</li>
                                                    <li>Add security of clear conditions, or the opposite.</li>
                                                    <li>Add recurrent products to the top of the home page.</li>
                                                    <li>Remove “Already delivered button”.</li>
                                                    <li>Add pre-tax price.</li>
                                                    <li>Add order confirmation number.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ping-pong-photo pb-5">
                                        <div className="col-11 col-md-10 mx-auto">    
                                            <div className="row align-items-center">
                                                <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                    <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/security.png'} 
                                                        alt="Security theme"/>
                                                </div>
                                                <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                    <div className="row mb-3 align-items-center">
                                                        <div className="col-2">
                                                            <div class="avatar avatar-sm">
                                                                <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                    1
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-10">
                                                            <h4 className="text-left mb-0">Security</h4>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <ul className="mb-5 mb-md-0">
                                                        <li>Drone operator was added in order to give the end user the security that a human is supervising the delivery.</li>
                                                        <li>"Weather conditions" was added in order to keep the situation of the package transparent to the end user.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-12 col-md-6 px-0 pr-md-4">
                                                    <div className="row mb-3 align-items-center">
                                                        <div className="col-2">
                                                            <div class="avatar avatar-sm">
                                                                <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                    2
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-10">
                                                            <h4 className="text-left mb-0">Understanding</h4>
                                                        </div>
                                                    </div>
                                                    <ul className="mb-5 mb-md-0">
                                                        <li>"Home categories" were removed for the user to get to the products faster.</li>
                                                        <li>Categories are now filters, and the user's recurrent products are presented first.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-md-6 px-0 pl-md-4">
                                                    <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/understanding.png'} 
                                                            alt="Understanding of home theme"/>
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                    <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/legibility.png'} 
                                                        alt="Legibility theme"/>
                                                </div>
                                                <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                    
                                                <div className="row mb-3 align-items-center">
                                                        <div className="col-2">
                                                            <div class="avatar avatar-sm">
                                                                <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                    3
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-10">
                                                            <h4 className="text-left mb-0">Legibility</h4>
                                                        </div>
                                                    </div>
                                                    
                                                    <p className="mb-5 mb-md-0">
                                                        Human Interface Guidelines were applied in order to give the user the most standard font size.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-12 col-md-6 px-0 pr-md-4">
                                                    <div className="row mb-3 align-items-center">
                                                        <div className="col-2">
                                                            <div class="avatar avatar-sm">
                                                                <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                    4
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-10">
                                                            <h4 className="text-left mb-0">Financial security</h4>
                                                        </div>
                                                    </div>
                                                    <ul className="mb-5 mb-md-0">
                                                        <li>"Before taxes" signs were added to the products for the user to estimate the final price.</li>
                                                        <li>An order confirmation number was added to give a receipt equivalent to the user.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-md-6 px-0 pl-md-4">
                                                    <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/financial-security.png'} 
                                                            alt="Product filetering theme before and after"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                 {/* Centred Important Description */}
                                 <div className="row centred-description-important pb-5 odd">
                                    <div className="col-11 col-md-10 mx-auto text-left">
                                        <h3 className="mb-3 mt-5 pt-4 text-center"> Moving on to the visuals</h3>
                                        
                                        <h5>Voice and tone</h5>
                                        
                                        <p className="">
                                            From initial research I decide voice and tone should depict:
                                        </p>
                                        <ul className="mb-4">
                                            
                                            <li>Professional, reliable.</li>
                                            <li>Geometric shapes to portray technology.</li>
                                            <li>Soft accessible colors that could be related to clear skies.</li>
                                            <li>Brand: Faster, No-contact, Delivers to any location.</li>
                                        </ul>

                                        <h5 className="mb-3">Moodboard</h5>
                                        <img className="portfolio-img mb-4" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/moodboard.png'} alt="Moodboard" />
                                        <h5 className="mb-3">Color exploration</h5>
                                        <img className="portfolio-img mb-4" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/color-test.png'} alt="Moodboard" />
                                        
                                        <h5>Type and Logo</h5>
                                        <ul className="mb-4">
                                            <li>As described before, I wanted to take advantage of the geometric style for its modern, technical, futuristic, or even digital appearance.</li>
                                            <li>After a few explorations I chose Red Hat Display for titles and Red Hat Text for reading.</li>
                                            <li>For the icon I took the Red Hat Display letter "O" and did some cuts to portray drone helixes.</li>
                                        </ul>
                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/type-explorations.png'} alt="type explorations" />
                                        <p>-Typography explorations</p>
                                        <img className="portfolio-img mt-3" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/logotype.png'} alt="type explorations" />
                                        <p>-Logo design</p>
                                      </div>
                                    </div>
                                    {/* Triple Text */}
                                    <div className="row triple-text odd pt-4">
                                        <div className="col-11 col-md-10 mx-auto">
                                            <h3 className="text-center my-3 mt-5">Usability study: round 2</h3>
                                            <h4 className="my-2">Study details</h4>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <h4>Research Questions</h4>
                                                    <ul className="ml-0">
                                                        <li>Do users understand that frequent products can be changed with the top menu?</li>
                                                        <li>Can users complete the purchase process faster?</li>
                                                        <li>Are screens legible?</li>
                                                        <li>How confident are users in the app and its functionalities?</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <h4>Participants</h4>
                                                    <ul>
                                                        <li>3 female, 2 male.</li>
                                                        <li>Ages around 33 years old.</li>
                                                        <li>Living within the USA.</li>
                                                        <li>3 with vision limitations.</li>
                                                        <li>1 used screen reader.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <h4>Methodology</h4>
                                                    <ul>
                                                        <li>Moderated usability study</li>
                                                        <li>Remote</li>
                                                        <li>Users were asked to use a high-fidelity Figma Prototype:</li>
                                                    </ul>
                                                    <div className="row call-to-action mt-4">
                                                        <div className="col-12 mx-auto text-left">
                                                            <a className='dark btn btn-outline-secondary' href="https://www.figma.com/proto/Oky2PjyH92He0T9wvv6lhj/Drotini?node-id=344%3A3455&viewport=405%2C509%2C0.19&scaling=min-zoom&starting-point-node-id=428%3A3398&show-proto-sidebar=1" target="_blank" rel="noreferrer">
                                                                LAUNCH PROTOTYPE
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        

                                        </div>
                                   
                                    </div>
                                    <div className="row triple-text py-5 mb-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Insights</h4>
                                        <div className="row text-center">
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Screen reader proof</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    Users need a more ordered way to read important information.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Current state</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    Users need a better way to understand where are they in the journey?
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Terminology</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    Users need more standard terminology.
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                4
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Color distractions</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    Users need less color distractions.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                 {/* Centred Important Description */}
                                 <div className="row centred-description-important pt-4">
                                    <div className="col-12 mx-auto">
                                        <div className="row">
                                            <div className="col-10 mx-auto text-left">
                                                <h4 className="mb-3 text-left">Refinement</h4>
                                                <p className="mb-3 text-left">
                                                    After a revisit to accessibility standards and a second round of usability test, this was the refinement I did to the design:
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ping-pong-photo pb-5">
                                        <div className="col-11 col-md-10 mx-auto">    
                                            <div className="row align-items-center">
                                                <div className="col-12 col-md-6 px-0 pr-md-4 mb-5 mb-md-0">
                                                    <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/round2us.png'} 
                                                        alt="accessibility and standarization to high fidelity screen"/>
                                                </div>
                                                <div className="col-12 col-md-6 px-0 pl-md-4 ">
                                                    <div className="row mb-3 align-items-center">
                                                        
                                                        <div className="col-12">
                                                            <h4 className="text-left mb-0">Screen reader proof, current state and terminology</h4>
                                                        </div>
                                                    </div>
                                                    
                                                    <ul className="mb-5 mb-md-0">
                                                        <li>Screens were corrected so their vertical rhythm was easier to read for a person using a screen reader.</li>
                                                        <li>Bottom menu states now present a selected background makes the user more confident in knowing what state they are in.</li>
                                                        <li>Terminology is now Shopify standard so users are familiar with what action they are performing.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-12 col-md-6 px-0 pr-md-4">
                                                    <div className="row mb-3 align-items-center">
                                                     
                                                        <div className="col-12">
                                                            <h4 className="text-left mb-0">Color distractions</h4>
                                                        </div>
                                                    </div>
                                                    <p className="mb-5 mb-md-0">
                                                        Color now changes only to denote the state the user is in the app: checkout process, shipping or neutral.
                                                    </p>
                                                </div>
                                                <div className="col-12 col-md-6 px-0 pl-md-4">
                                                    <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/round2us1.png'} 
                                                            alt="High fidelity screens of right use of color to denotate state"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 {/* Triple Text */}
                                 <div className="row triple-text pb-5 mb-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Accessibility considerations</h4>
                                        <div className="row text-center">
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Vertical Rythm</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Screens are readable from top to bottom by a screen reader.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Navigation Icons</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    Used icons to help users understand navigation easier.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Color-blind-proof</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    All the colors were contrast and color blind tested.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div className="row centred-description-important py-5 odd">
                                      <div className="col-11 col-md-10 mx-auto text-left">

                                        <h5 className="mt-5 mb-3">Hi-Fi Screens</h5>
                                        <div className="row mt-2">
                                            <div className="col-md-4 col-12">
                                                <p className="mb-0 font-secondary">SPLASH SCREEN</p>
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/hifi/splash.png'} alt="type explorations" />
                                                
                                                <ul className="mt-4">
                                                    <li>Call to action comes right after stating the user needs "Faster", "No contact" and "To any location".</li>
                                                    <li>Logotype is first, followed by brand slogan.</li>
                                                    <li>Image depicts strong security and willingness to get anywhere to reinforce the user's goals.</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <p className="mb-0 font-secondary">HOME</p>
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/hifi/home.png'} alt="type explorations" />
                                                
                                                <ul className="mt-4">
                                                    <li>Home was simplified even more, so the user has its recurrent products first hand.</li>
                                                    <li>Use of brand color.</li>
                                                    <li>Iconography plays the role of call-to-action.</li>
                                                </ul>
                                            </div>
                                           
                                            <div className="col-md-4 col-12">
                                                <p className="mb-0 font-secondary">PRODUCT VIEW</p>
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/hifi/product.png'} alt="type explorations" />
                                                
                                                <ul className="mt-4">
                                                    <li>Call to action is add to cart with primary color, so the recurrent user can find it fast.</li>
                                                    <li>Estimated delivery time is displayed so the user knows it before buying attempt.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                             <div className="col-md-4 col-12">
                                                <p className="mb-0 font-secondary">LIVE TRACKING</p>
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/hifi/live-tracking.png'} alt="type explorations" />
                                                <ul className="mt-4">
                                                    <li>Map and live route are front and center, to give the user the status information as a priority.</li>
                                                    <li>A bubble of the package and drone information is also presented at the bottom.</li>
                                                    <li>"Report a problem" is grouped with drone information for the user to have a way to "control" it.</li>
                                                </ul>
                                            </div>
                                            
                                            <div className="col-md-4 col-12">
                                                <p className="mb-0 font-secondary">CHECKOUT</p>
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/hifi/checkout.png'} alt="type explorations" />
                                                <ul className="mt-4">
                                                    <li>Payment and Shipping information are now in summary, since cart already had specific information and the user can focus on prices and totals.</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <p className="mb-0 font-secondary">CART</p>
                                                <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/DROTINI/hifi/cart.png'} alt="type explorations" />
                                                
                                                <ul className="mt-4">
                                                    <li>Cart summarizes prices, products and delivery so the user can have all the information before making a decision.</li>
                                                    <li>Map and delivery location is still priority so the user knows where the package is going to be dropped.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row centred-description-important pb-5">
                                    <div className="col-11 col-md-10 mx-auto text-left">
                                        <h3 className="mb-3 mt-5 pt-4 text-left">Next Steps</h3>
                                        <ul className="mt-4">
                                            <li>Include a tutorials section in case there is still doubt on how to use it.</li>
                                            <li>Location coverage request. </li>
                                            <li>Sound notifications to reinforce brand.</li>
                                       </ul>
                                        <h3 className="mb-3 mt-5 text-left">What did I learn from this project?</h3>
                                        <ul className="mt-4">
                                            <li>There are plenty of opportunities to solve problems related to this one, since big brands are already trying and failing.</li>
                                            <li>This was the first time I dived deeper into visual accessibility and tested contrast against different types of color blinding. </li>
                                            <li>End users are increasingly aware of their needs when it comes to apps.</li>
                                       </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="mb-3">Related Projects</h3>
                                        <Related 
                                                
                                                title1="CYBERSECURITY MANAGED SERVICE"
                                                image1="/images/current/featured.png"
                                                type1="Current" 
                                                url1="Cyber"
                                                text1="ONGOING PROJECT"

                                                title2="SALARY COMPARISON TOOL"
                                                image2="/images/challenge/xcorp/feature.png"
                                                type2="Challenge" 
                                                text2="CHALLENGE"
                                                url2="Xcorp"
                                                 
                                                 />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Layout>
            </React.Fragment>
        )
    }
}
export default DROTINI;