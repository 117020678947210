import React from 'react'
import { Link } from 'react-router-dom';

class PortfolioShuffle extends React.Component{


    render(){
        return (
            <React.Fragment> 
                <section className="section general-portfolio">
                    <div className="container-fluid">
                        <div className="row mt-5 mb-3">
                            <div className="col-md-10 col-11 mx-auto text-left px-0">
                                <h2 className="font-tertiary">My work</h2>
                                <p className="px-3 px-md-0 pt-2 mb-2">
                                    Feel free to contact me for a walk-through:
                                </p>
                            </div>
                        </div>
                        {/* ping pong photo descriptions */}
                        <div className="row ping-pong-photo">
                            <div className="col-11 col-md-10 mx-auto">
                            <div className="row align-items-center mb-5">
                                    <div className="col-12 col-md-6 px-0 pr-md-5">
                                        <Link to='/Portfolio/Dashboard'>
                                            <h2 className="mb-1 text-left">Cybersecurity Dashboard Redesign</h2>
                                        </Link>
                                        <span className="mb-4 text-left tagline case-study">CASE STUDY</span>

                                        <ul className="lead-paragraph mb-3 mb-md-0">
                                            <li>Redesign project for Protexxa's SaaS application, Defender, aimed at securing enterprise users.</li>
                                            <li>Focus on improving usability and aligning with Protexxa's mission to enhance global security.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Link to='/Portfolio/Dashboard'>
                                            <img className="portfolio-img" src={process.env.PUBLIC_URL + '/images/challenge/proserpexa/cover.png'} 
                                                alt="Cybersecurity Dashboard for Protexxa"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-5">
                                <div className="col-12 col-md-6 px-0 pr-md-5">
                                    <Link to='/Portfolio/NetworkProviderDashboard'>
                                        <h2 className="mb-1 text-left">Network Provider Remediation Visualization</h2>
                                    </Link>
                                    <span className="mb-4 text-left tagline case-study">CASE STUDY</span>

                                    <ul className="lead-paragraph mb-3 mb-md-0">
                                        <li>Collaborative project to visualize third-party security frameworks for prioritized remediation actions.</li>
                                        <li>Designed an interactive, action-oriented network visualization tool to simplify complex security data.</li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                    <Link to='/Portfolio/NetworkProviderDashboard'>
                                        <img className="portfolio-img" src={process.env.PUBLIC_URL + '/images/portfolio/viz/home.png'} 
                                            alt="Network Provider Cyber Remediation Visualization"/>
                                    </Link>
                                </div>
                            </div>
                                <div className="row align-items-center mb-5">
                                    <div className="col-12 col-md-6 px-0 pr-md-5">
                                        <Link to='/Current/Cyber'>
                                            <h2 className="mb-1 text-left">Managed Services Design for the Cybersecurity world</h2>
                                        </Link>
                                        <span className="mb-4 text-left tagline current"> ONGOING
                                        </span>

                                        <ul className="lead-paragraph mb-3 mb-md-0">
                                            <li>A glimpse of my current: Data Visualizations and Dashboards for VIPs within Businesses.</li>
                                            <li>Omni-channel Service Design for: web, email, slack and more.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Link to='/Current/Cyber'>
                                            <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/current/featured.png'} 
                                                    alt="Cybersecurity"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-5">
                                    <div className="col-12 col-md-6 px-0 pr-md-5">
                                       
                                        <Link to='/Portfolio/Thestralcorp'>
                                            <h2 className="mb-1 text-left">Dashboard SaaS UX/UI</h2>
                                        </Link>
                                        <span className="mb-4 text-left tagline case-study"> CASE STUDY
                                        </span>

                                        <ul className="lead-paragraph mb-3 mb-md-0">
                                            <li>Collaborated in the research and visuals of several SaaS components.</li>
                                            <li>You can see my process and resources for Information Architecture, Wireframing/Prototyping, Design Systems, etc.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Link to='/Portfolio/Thestralcorp'>
                                            <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/home/thestralcorp.png'} 
                                                    alt="Thestralcorp"/>
                                        </Link>
                                    </div>
                                </div>
                               
                                <div className="row align-items-center mb-5">
                                    <div className="col-12 col-md-6 px-0 pr-md-5">
                                        
                                        <Link to='/Portfolio/PentaCad'>
                                            <h2 className="mb-1 text-left">Creating a MS Software CAD Editor</h2>
                                        </Link>
                                        <span className="mb-4 text-left tagline case-study"> CASE STUDY
                                        </span>

                                        <ul className="lead-paragraph mb-3 mb-md-0">
                                            <li>Sprinting ahead and indroduce components and a brand new design system to an already running developing cycle.</li>
                                            <li>Includes: use of agile sprints methodology, thought process to create and test interactions and visuals for a Desktop Software.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Link to='/Portfolio/PentaCad'>
                                            <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/home/pentacad.png'} 
                                                    alt="PentaCad"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-5">
                                    <div className="col-12 col-md-6 px-0 pr-md-5">
                                        <Link to='/Challenge/Xcorp'>
                                            <h2 className="mb-1 text-left">Industry Salary Comparison Tool</h2>
                                        </Link>
                                        <span className="mb-4 text-left tagline challenge">CHALLENGE
                                        </span>

                                        <ul className="lead-paragraph mb-3 mb-md-0">
                                            <li>I did this challenge to showcase my overall design process and skills.</li>
                                            <li>Includes: high and low levels of my thought process and resources I use, from research to mid fidelity mockups.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Link to='/Challenge/Xcorp'>
                                            <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/feature.png'} 
                                                    alt="X Challenge"/>
                                        </Link>
                                    </div>
                                </div>
                                
                               
                                <div className="row align-items-center mb-5">
                                    <div className="col-12 col-md-6 px-0 pr-md-5">
                                        
                                        <Link to='/Portfolio/Epico'>
                                            <h2 className="mb-1 text-left">Coding a Responsive Dashboard for Entrepeneurs</h2>
                                        </Link>
                                        <span className="mb-4 text-left tagline case-study"> CASE STUDY
                                        </span>

                                        <ul className="lead-paragraph mb-3 mb-md-0">
                                            <li>Use HTML and SCSS (Bootstrap5) to create components for developers to use.</li>
                                            <li>Creation of Dashboard components from an existent Design System.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Link to='/Portfolio/Epico'>
                                            <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/portfolio/epico/cover.png'} 
                                                    alt="Epico"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-5">
                                    <div className="col-12 col-md-6 px-0 pr-md-5">
                                        
                                        <Link to='/Portfolio/Drotini'>
                                            <h2 className="mb-1 text-left">Drone Delivery iOS App</h2>
                                        </Link>
                                        <span className="mb-4 text-left tagline case-study"> CASE STUDY
                                        </span>

                                        <ul className="lead-paragraph mb-3 mb-md-0">
                                            <li>Use of design thinking resources to create the UI/UX for an iOS mobile application.</li>
                                            <li>Special attention to competitive audit, wireframes, usability study and high fidelity prototypes.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Link to='/Portfolio/Drotini'>
                                            <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/home/DROTINI.png'} 
                                                    alt="Drotini"/>
                                        </Link>
                                    </div>
                                </div>
                              
                                
                            </div>
                        </div>
                    </div>
                </section>       
    
                        
            </React.Fragment>
        )
    }
}

export default PortfolioShuffle;