import React from 'react'
import { Link } from "react-router-dom"
import Related from '../reusable/hoverable'
import Hoverable from '../reusable/hoverable';

var Scroll   = require('react-scroll');
var Element  = Scroll.Element;



const HomePortfolio = () => {
    return (
        <React.Fragment>
            <Element name="HomePortfolio">
                <section className="section home-portfolio odd" id="portfolio">
                    <div className="container-fluid">
                
                        {/* ping pong photo descriptions */}
                        <div className="row ping-pong-photo">
                            <div className="col-11 col-md-10 mx-auto">
                                 <div className="row align-items-center mb-5">
                                        <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                       
                                        <Hoverable 
                                            image="/images/challenge/proserpexa/cover.png"
                                            type="Portfolio" 
                                            url="Dashboard"
                                            text="CYBERSECURITY DASHBOARD"    
                                                />
                                    </div><div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Hoverable   
                                                image="/images/portfolio/viz/home.png"
                                                type="Portfolio" 
                                                url="NetworkProviderDashboard"
                                                text="Cyber Remediation Visualization"  
                                                    />
                                        
                                    </div>
                                
                                   
                                </div>
                                <div className="row align-items-center mb-5">
                                <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                <Hoverable     
                                            image="/images/current/featured.png"
                                            type="Current" 
                                            url="Cyber"
                                            text="CYBERSECURITY MANAGED SERVICES"
                                                />
                                    </div>
                                    <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Hoverable     
                                            image="/images/home/thestralcorp.png"
                                            type="Portfolio" 
                                            url="Thestralcorp"
                                            text="SAAS OPERATIONS MANAGER"
                                                />
                                    </div>
                                   
                                </div>
                               
                                 {/* See More */}
                                <div className="row call-to-action">
                                    <div className="col-12 mx-auto px-0 text-center">
                                        <Link to="/Portfolio" className="light btn btn-primary odd">MORE PROJECTS</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                                
                        
                    </div>
                </section>
            </Element>
        </React.Fragment>
    )
}

export default HomePortfolio;